import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ISpecialistListFilters } from '../../../../1_shared/config/interfaces';
import { Select } from '../../../../1_shared/ui';

import styles from '../SpecialistFilters.module.scss';

const FilterSelect = ({
  name,
  options,
  defaultValue,
  handleFormSubmit,
  allowClear = false,
  placeholder,
}: {
  name: 'sortRequest.order' | 'specShowType';
  options: { value: string | number; label: string }[];
  defaultValue?: string | number;
  handleFormSubmit: (data: ISpecialistListFilters) => void;
  allowClear?: boolean;
  placeholder: string;
}): ReactElement => {
  const { control, handleSubmit, setValue } =
    useFormContext<ISpecialistListFilters>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
          <Select
            className={styles.sortSelect}
            onChange={val => {
              onChange(val);
              handleSubmit(handleFormSubmit)();
            }}
            allowClear={allowClear}
            onClear={() => {
              setValue(name, undefined);
              handleSubmit(handleFormSubmit)();
            }}
            options={options}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
          />
        )}
    />
  );
};

export default FilterSelect;
