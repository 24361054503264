import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ISpecialistListFilters } from '1_shared/config/interfaces';
import { ChipsSelected, Typography } from '1_shared/ui';
import { ESortType } from '4_widgets/SpecialistFilters/ui/SpecialistFilters';

import { getDictionaries } from '../../../1_shared/api/dictionary';
import useSWRWithCache from '../../../1_shared/api/lib/useSWRWithCache';
import { IOption } from '../../../1_shared/config/interfaces/IOption';

import { IKeyThemesProps } from './interfaces/IKeyThemesProps';

import styles from './KeyThemes.module.scss';

const KeyThemes = ({ onChange }: IKeyThemesProps): ReactElement => {
  const { control, handleSubmit, setValue } =
    useFormContext<ISpecialistListFilters>();

  const { data: keyThemesOptions } = useSWRWithCache<IOption[]>(
    '/spec/key-themes',
    getDictionaries,
  );

  const handleFormSubmit = (data: ISpecialistListFilters) => {
    const filters = {
      filter: {
        ...data,
        sortRequest: {
          order: data?.sortRequest.order ?? 'ASC',
          sortType: data?.sortRequest.order
            ? ESortType.BY_EXPERIENCE
            : ESortType.BY_NEAREST_SESSION,
        },
      },
    };
    onChange(filters);
  };

  const handleFieldChange = () => {
    handleSubmit(handleFormSubmit)();
  };

  const deleteThemas = (
    keyThemas: string[],
    value: string,
    onChange: (value: string[]) => void,
  ) => {
    const tempArr = keyThemas.filter(el => el !== value);
    onChange(tempArr);
    setValue('keyThemes', tempArr);
  };

  return (
    <div className={styles.root}>
      <Typography type="title">Не смогли выбрать?</Typography>
      <Typography>Можно расширить поиск, убрав некоторые параметры</Typography>
      <Controller
        control={control}
        name="keyThemes"
        render={({ field: { value, onChange } }) => {
          const rusThemas: IOption[] = value?.map(el =>
            keyThemesOptions?.find((options: IOption) => options.value === el),
          );
          return (
            <div className={styles.chipsBlock}>
              {rusThemas?.map((thema: IOption) => (
                <ChipsSelected
                  key={thema.value}
                  value={thema.label}
                  onDelete={() => {
                    deleteThemas(value, thema.value, onChange);
                    handleFieldChange();
                  }}
                />
              ))}
            </div>
          );
        }}
      />
    </div>
  );
};

export default KeyThemes;
