import { Controller, useFormContext } from 'react-hook-form';
import { Radio } from 'antd';

import { EContactType } from '1_shared/config/enums/EContactType';
import {
  onKeyDownPhoneInput,
  phoneFormConvert,
} from '1_shared/lib/helpers/phoneConvert';
import { Input, Radio as CustomRadio, Typography } from '1_shared/ui';

import { IAnketaForm } from '../../../1_shared/config/interfaces/IAnketaForm';
import { CustomTypography } from '../../../1_shared/ui/CustomTypography';

import styles from './ContactInfo.module.scss';

const ContactInfo = () => {
  const {
    control,
    getValues,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext<IAnketaForm>();
  watch();

  return (
    <section className={styles.root}>
      <Typography type="title">Контактная информация</Typography>
      <div className={styles.itemWrap}>
        <Typography className={styles.subtitle}>
          Как к вам обращаться
        </Typography>
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              name="name"
              value={value}
              onChange={onChange}
              placeholder="ФИО"
            />
          )}
        />
      </div>
      <div className={styles.itemWrap}>
        <Typography className={styles.subtitle}>Номер телефона</Typography>
        <Controller
          name="phone"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              name="phone"
              value={value}
              autocomplete="on"
              onChange={e => {
                onChange(phoneFormConvert(e.target.value));
              }}
              onKeyDown={onKeyDownPhoneInput}
              placeholder="Телефон"
            />
          )}
        />
      </div>
      <div className={styles.itemWrap}>
        <Typography className={styles.subtitle}>
          Как с вами связаться
        </Typography>
        <Controller
          control={control}
          name="chooseContactType"
          render={({ field: { value, onChange } }) => (
            <Radio.Group
              value={value}
              onChange={(e) => {
                setValue('telegram', undefined);
                onChange(e.target.value);
              }}
              className={styles.radioGroup}
            >
              <CustomRadio value={EContactType.Sms}>Смс</CustomRadio>
              <CustomRadio value={EContactType.Telegram}>Телеграмм</CustomRadio>
              <CustomRadio value={EContactType.WhatsApp}>WhatsApp</CustomRadio>
              <CustomRadio value={EContactType.CallMe}>
                Позвоните мне сразу
              </CustomRadio>
            </Radio.Group>
          )}
        />
      </div>
      {getValues('chooseContactType') === EContactType.Telegram && (
        <div>
          <Typography className={styles.subtitle}>Никнейм Телеграмм</Typography>
          <Controller
            name="telegram"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                name="telegram"
                value={value}
                onChange={onChange}
                placeholder="dotherapy"
                prefix="@"
              />
            )}
          />
          {errors?.telegram && (
            <CustomTypography type="description" className={styles.error}>
              {errors?.telegram?.message}
            </CustomTypography>
          )}
        </div>
      )}
    </section>
  );
};

export default ContactInfo;
