export enum ELoginStatus {
  NotFound = 'NOT_FOUND',
  FoundUser = 'FOUND_USER',
  NeedPassword = 'NEED_PASSWORD',
  NeedApprove = 'NEED_APPROVE',
  CodeSend = 'CODE_SEND',
  CodeSended = 'CODE_SENDED',
  CodeVerified = 'CODE_VERIFIED',
  None = 'NONE',
}
