import React from 'react';
import { useLocation } from 'react-router-dom';

import { Select, Typography } from '1_shared/ui';

import useCreateOptions from '../model/useCreateOptions';

import { IDataSelectProps } from './interfaces/IDataSelectProps';

import styles from './DateSelect.module.scss';

const SlotSelect = ({
  slots,
  value,
  onChange,
}: IDataSelectProps): React.ReactElement => {
  const { options } = useCreateOptions(slots);
  const { state } = useLocation();
  return (
    <div className={styles.dateSelect}>
      <Select
        options={options}
        value={options.find(el => el.value === value)}
        onChange={onChange}
        defaultValue={
          (state as any).slot && options.find(el => el.value === (state as any).slot.slotId)
        }
      />
      <button className={styles.btn} type='button' onClick={() => onChange(slots?.[0]?.slotId)}>
        <Typography type="description" className={styles.underText}>
          Выбрать ближайшее доступное время
        </Typography>
      </button>
    </div>
  );
};

export default SlotSelect;
