import { UploadFile } from 'antd';
import * as yup from 'yup';

import { IFirstFormData } from '../interfaces/IFirstFormData';

import { userInfoSchema } from './userInfoSchema';

export const firstFormTabsSchema = yup.object().shape({
  mediaFiles: yup.array().of(yup.mixed<UploadFile>()),
  data: yup
    .object<IFirstFormData>()
    .shape({
      userInfo: userInfoSchema,
      startWorkDate: yup.string().required('Обязательное поле'),
      specialities: yup
        .array()
        .of(yup.string())
        .min(1, 'Обязательное поле')
        .required('Обязательное поле'),
      workWiths: yup
        .array()
        .of(yup.string())
        .min(1, 'Обязательное поле')
        .required('Обязательное поле'),
      workMethods: yup
        .array()
        .of(yup.string())
        .min(1, 'Обязательное поле')
        .required('Обязательное поле'),
      keyThemes: yup
        .array()
        .of(yup.string())
        .min(1, 'Обязательное поле')
        .required('Обязательное поле'),
      about: yup.string().required('Обязательное поле'),
      briefAboutYourself: yup.string().required('Обязательное поле'),
    })
    .required(),
});
