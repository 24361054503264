import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { isArray } from 'lodash';

import { TInputArrayField } from '../../../../../../1_shared/config/types/TInputArrayField';
import { educationArrayFieldNames } from '../../../../../../1_shared/constants/educationArrayFieldNames';
import { Typography } from '../../../../../../1_shared/ui';
import { CustomTypography } from '../../../../../../1_shared/ui/CustomTypography';
import SpecialistEditProfileInput from '../../../../../../1_shared/ui/SpecialistEditProfile/SpecialistEditProfileInput';
import { useAuthContext } from '../../../../../../app/module/lib/hooks/useAuthContext';
import { userObjectConverterEducation } from '../../../../helpers/userObjectConverter';

import styles from './EducationComponent.module.scss';

const EducationComponent = ({ fieldArrayName }: { fieldArrayName: string }) => {
  const { user } = useAuthContext();

  const fieldProps = (educationArrayFieldNames as any)?.[fieldArrayName];
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, replace } = useFieldArray<any>({
    control,
    name: fieldArrayName,
  });

  const max = fieldProps?.max || -1;

  // TODO: сжечь после переноса редактирования профиля на новый бэкенд
  useEffect(() => {
    const data = userObjectConverterEducation(user, fieldArrayName);
    if (isArray(data)) {
      return replace(
        data?.map((el: any) => {
          const issuedAt =
            (el?.issued_at && el.issued_at * 1000) ?? el?.issuedAt;
          const dateFrom =
            (el?.date_from && el.date_from * 1000) ?? el?.dateFrom;
          return {
            ...el,
            issued_at:
              !!issuedAt && dayjs(issuedAt).isValid()
                ? dayjs(issuedAt).year()
                : undefined,
            date_from:
              !!dateFrom && dayjs(dateFrom).isValid()
                ? dayjs(dateFrom).year()
                : undefined,
          };
        }),
      );
    }

    return replace({ degree: data });
  }, []);

  return (
    <div className={styles.root}>
      <Typography type="textM">{fieldProps?.label}</Typography>
      <div className={styles.wrapper}>
        {fields.map((field, index) => (
          <div key={field.id} className={styles.fieldWrapper}>
            <div className={styles.field}>
              <div className={styles.education}>
                {fieldProps?.array.map((field: TInputArrayField) => (
                    <div className={styles.input}>
                      <SpecialistEditProfileInput
                        {...field}
                        type={field?.type}
                        name={`${fieldArrayName}.${index}.${field.name}`}
                        status={
                          (errors?.[fieldArrayName] as any)?.[
                            index
                          ]?.[field.name]
                            ? 'error'
                            : undefined
                        }
                      />
                      {(errors?.[fieldArrayName] as any)?.[
                        index
                      ]?.[field.name] && (
                        <CustomTypography
                          type="description"
                          className={styles.error}
                        >
                          {
                            (errors?.[fieldArrayName] as any)?.[
                              index
                            ]?.[field.name]?.message
                          }
                        </CustomTypography>
                      )}
                    </div>
                  ))}
              </div>
              <Button
                className={styles.removeButton}
                type="link"
                onClick={() => {
                  remove(index);
                }}
              >
                <CloseOutlined />
              </Button>
            </div>
          </div>
        ))}
      </div>
      {fieldProps?.addButtonLabel && fields?.length !== max && (
        <Button
          type="link"
          className={styles.addButton}
          onClick={() => append({})}
        >
          <PlusOutlined className={styles.icon} />
          {fieldProps?.addButtonLabel}
        </Button>
      )}
    </div>
  );
};

export default EducationComponent;
