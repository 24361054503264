import useSWR from 'swr';

import { getSpecById } from '../specialistById/specService';

const useGetSpec = (specIdentification?: string) => {
  const { data: spec, isLoading } = useSWR(
    // `/spec/specialist/${specIdentification}`,
    `spec/api/v2/specialist/${specIdentification}`,
    getSpecById,
  );
  return { spec, isLoading };
};

export default useGetSpec;
