import { IOption } from '../../config/interfaces/IOption';
import useSWRWithCache from '../lib/useSWRWithCache';

import { getDictionaries } from './index';

const useGetDictionaries = () => {
  const { data: dictionary } = useSWRWithCache<IOption[]>(
    '/spec/specialities',
    getDictionaries,
  );

  const { data: dictionaryMethods } = useSWRWithCache<IOption[]>(
    '/spec/work-methods',
    getDictionaries,
  );

  const { data: dictionaryThemes } = useSWRWithCache(
    '/spec/key-themes',
    getDictionaries,
  );

  return {
    dictionary,
    dictionaryMethods,
    dictionaryThemes,
  };
};

export default useGetDictionaries;
