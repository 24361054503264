import { useLayoutEffect } from 'react';

import DotsAnketaMp4 from '1_shared/assets/video/dots_anketa.mp4';
import { Footer, Page } from '1_shared/ui';
import { AnketaTabsForm, Header } from '4_widgets';

import 'app/styles/global/global.scss';
import './testing.scss';

const Anketa = () => {
  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, []);

  return (
    <Page>
      <Header />
      <section className="testing">
        <AnketaTabsForm />
        <div className="video-wrap">
          <video
            src={DotsAnketaMp4}
            className="video-item"
            width="100%"
            height="auto"
            playsInline
            muted
            loop
            autoPlay
            preload="auto"
          />
        </div>
      </section>
      <Footer />
    </Page>
  )
};

export default Anketa;
