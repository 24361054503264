import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import BazarovImg from '1_shared/assets/images/experts/bazarov@2x.png';
import BazarovaImg from '1_shared/assets/images/experts/bazarova@2x.png';
import GolovinovImg from '1_shared/assets/images/experts/golovinov@2x.png';
import KovarskisImg from '1_shared/assets/images/experts/kovarskis@2x.png';
import KovpakImg from '1_shared/assets/images/experts/kovpak@2x.png';
import ShtukaturevaImg from '1_shared/assets/images/experts/shtukatureva@2x.png';
import SpivakovskayaImg from '1_shared/assets/images/experts/spivakovskaya@2x.png';
import TimoshkinaImg from '1_shared/assets/images/experts/timoshkina@2x.png';

import 'swiper/scss';
import 'app/styles/global/global.scss';
import './experts.scss';

const Experts = () => (
  <section className="experts">
    <div className="experts__container">
      <div className="experts__heading container">
        <h2 className="experts__title section-title">Наши эксперты</h2>
        <p className="experts__subtitle">
          Собрали экспертное сообщество профессионалов
          <br /> на базе Московского института психоанализа
        </p>
      </div>
      <Swiper
        spaceBetween={10}
        slidesPerView="auto"
        className="experts__list-wrapper"
        wrapperClass="experts__list"
        id="expertsSlider"
        mousewheel={{enabled: true, releaseOnEdges: true}}
        modules={[Mousewheel]}
        setWrapperSize
      >
        <div className="experts__list swiper-wrapper">
          <SwiperSlide className="experts__item swiper-slide">
            <img src={BazarovImg} alt="Т. Ю. Базаров" />
            <h3>Т. Ю. Базаров</h3>
            <p>
              Доктор психологических наук. Президент ассоциации бизнес
              психологов России
            </p>
          </SwiperSlide>
          <SwiperSlide className="experts__item swiper-slide">
            <img src={ShtukaturevaImg} alt="С. В. Штукарева" />
            <h3>С. В. Штукарева</h3>
            <p>
              Руководитель Высшей Школы Логотерапии. Победитель национального
              конкурса «Золотая Психея»
            </p>
          </SwiperSlide>
          <SwiperSlide className="experts__item swiper-slide">
            <img src={KovpakImg} alt="Д.В. Ковпак" />
            <h3>Д.В. Ковпак</h3>
            <p>
              Кандидат медицинских наук, Председатель Ассоциации
              когнитивно-поведенческой психотерапии
            </p>
          </SwiperSlide>
          <SwiperSlide className="experts__item swiper-slide">
            <img src={KovarskisImg} alt="Лявас Коварскис" />
            <h3>Лявас Коварскис</h3>
            <p>
              Доктор медицины
              <br /> Член Международной психоаналитической ассоциации (IPA)
            </p>
          </SwiperSlide>
          <SwiperSlide className="experts__item swiper-slide">
            <img src={GolovinovImg} alt="Е. И. Головинов" />
            <h3>Е. И. Головинов</h3>
            <p>
              Суггестивная психология, Эриксоновский гипноз,
              Психолог-консультант, гипнолог, гипнотерапевт
            </p>
          </SwiperSlide>
          <SwiperSlide className="experts__item swiper-slide">
            <img src={TimoshkinaImg} alt="А. А. Тимошкина" />
            <h3>А. А. Тимошкина</h3>
            <p>
              Клинический психолог, к.псх.н., психоаналитик, групп-аналитик,
              тренинг-аналитик и супервизор ЕАРПП (Россия) и ECPP (Vienna,
              Austria)
            </p>
          </SwiperSlide>
          <SwiperSlide className="experts__item swiper-slide">
            <img src={BazarovaImg} alt="Г. Т. Базарова" />
            <h3>Г. Т. Базарова</h3>
            <p>
              Практическая психология, Кандидат психологических наук.
              Бизнес-тренер, семейный психолог, психолог-консультант
            </p>
          </SwiperSlide>
          <SwiperSlide className="experts__item swiper-slide">
            <img src={SpivakovskayaImg} alt="А. С. Спиваковская" />
            <h3>А. С. Спиваковская</h3>
            <p>
              Психологическое консультирование, семейная терапия, Доктор
              психологических наук
            </p>
          </SwiperSlide>
        </div>
      </Swiper>
    </div>
  </section>
);

export default Experts;
