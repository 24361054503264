import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useMessage from 'antd/es/message/useMessage';
import dayjs from 'dayjs';
import useSWRMutation from 'swr/mutation';

import { EEditProfileFieldArrayNames } from '../../../../1_shared/config/enums/EFieldArrayNames';
import {
  IProfileEducationSchema,
  profileEducationSchema,
} from '../../../../1_shared/config/validationSchemas/profileEducationSchema';
import { Button as UiButton, Typography } from '../../../../1_shared/ui';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
import { editProfileEducation } from '../../model/service/specEditService';
import { mergeObjects } from '../OtherInformation/OtherInformation';

import EducationComponent from './ui/HighEducation/EducationComponent';

import styles from './SpecialistEditProfileEducation.module.scss';

const SpecialistEditProfileEducation = () => {
  const { user, setSpecUser } = useAuthContext();
  const [messageApi, contextHolder] = useMessage();

  const methods = useForm<IProfileEducationSchema>({
    resolver: zodResolver(profileEducationSchema),
  });
  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  const { trigger, isMutating, error } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileEducation,
  );

  const onSubmit = async (data: IProfileEducationSchema) => {
    const newObject = {
      [EEditProfileFieldArrayNames.DEGREE]:
        data?.academic_degree?.[0]?.degree || '',
      [`${EEditProfileFieldArrayNames.EDUCATION}`]:
        data?.[EEditProfileFieldArrayNames.EDUCATION].map((el: any) => ({
          ...el,
          date_from: dayjs(`${el?.date_from}`).isValid()
            ? dayjs(`${el?.date_from}`).add(3, 'hours').unix()
            : el?.date_from,
          id: el?.id || '',
          dateFrom: undefined,
        })) || [],
      [`${EEditProfileFieldArrayNames.PROFESSIONAL_RETRAINING}`]:
        data?.[EEditProfileFieldArrayNames.PROFESSIONAL_RETRAINING].map(
          (el: any) => ({
            ...el,
            issued_at: dayjs(`${el?.issued_at}`).isValid()
              ? dayjs(`${el?.issued_at}`).add(3, 'hours').unix()
              : el?.issuedAt,
            id: el?.id || '',
            issuedAt: undefined,
          }),
        ) || [],
      [`${EEditProfileFieldArrayNames.ADVANCED_TRAINING}`]:
        data?.[EEditProfileFieldArrayNames.ADVANCED_TRAINING].map(
          (el: any) => ({
            ...el,
            issued_at: dayjs(`${el?.issued_at}`).isValid()
              ? dayjs(`${el?.issued_at}`).add(3, 'hours').unix()
              : el?.issued_at,
            id: el?.id || '',
            issuedAt: undefined,
          }),
        ) || [],
      [`${EEditProfileFieldArrayNames.CERTIFICATES}`]:
        data?.[EEditProfileFieldArrayNames.CERTIFICATES].map((el: any) => ({
          ...el,
          issued_at: dayjs(`${el?.issued_at}`).isValid()
            ? dayjs(`${el?.issued_at}`).add(3, 'hours').unix()
            : el?.issued_at,
          id: el?.id || '',
          issuedAt: undefined,
        })) || [],
      utc_offset: 3,
    };

    const objectToSend = {
      [`${EEditProfileFieldArrayNames.DEGREE}`]:
        newObject?.[EEditProfileFieldArrayNames.DEGREE],
      [`${EEditProfileFieldArrayNames.EDUCATION}_attributes`]:
        newObject?.[EEditProfileFieldArrayNames.EDUCATION],
      [`${EEditProfileFieldArrayNames.PROFESSIONAL_RETRAINING}_attributes`]:
        newObject?.[EEditProfileFieldArrayNames.PROFESSIONAL_RETRAINING],
      [`${EEditProfileFieldArrayNames.ADVANCED_TRAINING}_attributes`]:
        newObject?.[EEditProfileFieldArrayNames.ADVANCED_TRAINING],
      [`${EEditProfileFieldArrayNames.CERTIFICATES}_attributes`]:
        newObject?.[EEditProfileFieldArrayNames.CERTIFICATES],
    };

    await trigger(objectToSend);

    if (data && !error && setSpecUser) {
      setSpecUser(mergeObjects(user, newObject));
      localStorage.setItem('user', JSON.stringify(mergeObjects(user, data)));
      return messageApi.open({
        type: 'success',
        content: 'Данные профиля отредактированы!',
      });
    }

    return messageApi.open({
      type: 'warning',
      content: 'Сохранить изменения не удалось!',
    });
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.wrapper}>
        <Typography type="title">Образование</Typography>
        {Object.values(EEditProfileFieldArrayNames).map((fieldName: string) => (
          <EducationComponent fieldArrayName={fieldName} />
        ))}

        <UiButton
          className={styles.saveButton}
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isMutating}
        >
          Сохранить
        </UiButton>
      </div>
      {contextHolder}
    </FormProvider>
  );
};

export default SpecialistEditProfileEducation;
