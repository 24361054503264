import { mutation } from '../../../1_shared/api/apiInstance';
import { ERequestType } from '../../../1_shared/api/interfaces/ERequestType';

import { ICancelInput } from './interfaces/ICancelInput';
import { IReminderSwitchInput } from './interfaces/IReminderSwitchInput';

export const cancelSession = mutation<ICancelInput, null>();

export const reminderSwitch = mutation<IReminderSwitchInput, null>(
  ERequestType.Patch,
);
