import { Spin } from 'antd';

import ProfileUser from '1_shared/assets/images/UserProfile.svg';

import ConfStatusesMap, {
  ConferenceStatus,
} from '../../../1_shared/constants/conference';

import styles from './SessionState.module.scss';

const SessionState = ({
  status,
  remoteUser,
  remoteMedia,
}: {
  status: ConferenceStatus;
  remoteUser: any;
  remoteMedia: any;
}) => (
  <div className={styles.root}>
    {!remoteUser && (
      <>
        {status !== 'not-alone' && status !== 'error' && <Spin />}
        <div className="flicker">{ConfStatusesMap[status]}</div>
      </>
    )}
    {(status === 'connected' || status === 'not-alone') &&
      remoteUser &&
      remoteMedia.video === 'removed' && (
        <div className={styles.remoteUser}>
          <div
            className={styles.remoteUserAvatar}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundImage: `url("${remoteUser?.avatarUrl || (remoteUser as any)?.mediaContentResponse?.[0]?.primaryContent?.previewLink || ProfileUser}")`,
            }}
          />
          {remoteUser?.nickname && remoteUser?.be_anonymous
            ? 'Аноним'
            : `${remoteUser.nickname || ''}`}
          {remoteUser?.first_name && remoteUser?.be_anonymous
            ? 'Аноним'
            : `${remoteUser?.firstName || ''} ${remoteUser?.lastName || ''}`}
          {status !== 'not-alone' && (
            <div className="flicker">подключается</div>
          )}
        </div>
      )}
  </div>
);
export default SessionState;
