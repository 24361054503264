import useSWRMutation from 'swr/mutation';

import { EReminderType } from '../../../1_shared/config/enums/EReminderType';
import { reminderSlotId } from '../api/details.service';

import { IHookActionOutput } from './interfaces/IHookActionOutput';

const useActionsOrder = (): IHookActionOutput => {
  const { trigger: reminderSwitchTr } = useSWRMutation(
    '/ss/reminder/switch/by-slot',
    reminderSlotId,
  );

  const reminderSwitchAction = async (slotId: string, type: EReminderType) => {
    await reminderSwitchTr({ slotId, type });
  };

  return { reminderSwitchAction };
};

export default useActionsOrder;
