import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';

import ProfileUser from '1_shared/assets/images/UserProfile.svg';

import { Button, Typography } from '../../../1_shared/ui';
import { SpecialistAvatar } from '../../../2_entities';
import { schema } from '../config/schema';
import useAddFeedback from '../module/useAddFeedback';

import { IFeedbackForm } from './interfaces/IFeedbackForm';
import { IModalFeedbackProps } from './interfaces/IModalFeedbackProps';

import styles from './IModalFeedback.module.scss';

const ModalFeedback = ({ open, onClose, session }: IModalFeedbackProps) => {
  const { control, formState, reset, handleSubmit } = useForm<IFeedbackForm>({
    resolver: yupResolver(schema),
  });
  const { addFeedbackFunc } = useAddFeedback();
  const [isFeedbackSending, setisFeedbackSending] = useState<boolean>(false);

  const onSubmit = async (data: IFeedbackForm) => {
    await addFeedbackFunc(data, String(session.id));
    setisFeedbackSending(true);
    setTimeout(() => {
      reset();
      onClose();
      setisFeedbackSending(false);
    }, 3000);
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
        <Typography type="title">Обратная связь о сеансе</Typography>
        <SpecialistAvatar
          firstName={session.clientNickname}
          imgPreview={ProfileUser}
        />
        <div className={styles.textWrap}>
          <Typography type="subtitle">ДАТА И ВРЕМЯ</Typography>
          <Typography>
            {dayjs(
              session?.start || session?.timePoint,
              'YYYY-MM-DDTHH:mm:ss',
            ).format('DD MMMM, HH:mm')}
          </Typography>
        </div>
        <Controller
          name="text"
          control={control}
          render={({ field }) => <TextArea {...field} rows={10} />}
        />
        {!isFeedbackSending ? (
          <div className={styles.footer}>
            <Button
              type="primary"
              disabled={!formState.isValid || formState.isSubmitting}
              htmlType="submit"
            >
              ПРИМЕНИТЬ
            </Button>
            <Button type="secondary" onClick={onClose}>
              ОТМЕНИТЬ
            </Button>
          </div>
        ) : (
          <div className={styles.feedbackAccepted}>Ваш отзыв отправлен!</div>
        )}
      </form>
    </Modal>
  );
};

export default ModalFeedback;
