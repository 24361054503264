import { Breadcrumbs2, Footer,Order } from '1_shared/ui';
import Page from '1_shared/ui/Page/ui/Page';
import { Header } from '4_widgets';

const ReactOrderPage = () => (
  <Page>
    <Header />
    <Breadcrumbs2 />
    <Order />
    <Footer />
  </Page>
);

export default ReactOrderPage;
