export const priceOptions = [
  { label: 'до 3 000 р', value: { min: 0, max: 3000 } },
  { label: 'до 6 000 р', value: { min: 0, max: 6000 } },
  { label: 'до 10 000 р', value: { min: 0, max: 10000 } },
];

export const timeOptions = [
  { label: 'Утро', value: { start: '08:00', end: '11:59' } },
  { label: 'День', value: { start: '12:00', end: '15:59' } },
  { label: 'Вечер', value: { start: '16:00', end: '23:59' } },
];
