import { useState } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { truncate } from 'lodash';

import { Typography } from '1_shared/ui';

import { ISpecDescriptionProps } from './interfaces/ISpecSpecializtionProps';

import styles from './SpecDescription.module.scss';

const SpecDescription = ({
  description,
  className = '',
  dark = false,
  label = 'ОПИСАНИЕ',
}: ISpecDescriptionProps) => {
  const [showFull, setShowFull] = useState(false);
  const descriptionModified = showFull
    ? description
    : truncate(description, { length: 300, omission: '' });
  const truncateButton = showFull ? (
    <div
      className={cn(styles.outline, styles.centered)}
      onClick={() => setShowFull(prevState => !prevState)}
    >
      Свернуть
    </div>
  ) : (
    <EllipsisOutlined
      className={styles.outline}
      size={20}
      onClick={() => setShowFull(prevState => !prevState)}
    />
  );

  return (
    <div className={cn(styles.root, { [className]: className !== '' })}>
      <Typography type="description">{label}</Typography>
      <div>
        <div
          key={label}
          className={cn(styles.description, { [styles.darkText]: dark })}
        >
          {descriptionModified}
          {description?.length > 300 && truncateButton}
        </div>
      </div>
    </div>
  );
};

export default SpecDescription;
