import styles from './PreparationHelp.module.scss';

const PreparationHelp = () => (
  <div className={styles.root}>
    <ul className={styles.list}>
      <li>Разрешите использовать микрофон и камеру на устройстве</li>
      <li>
        Закройте окна других приложений с видео - Zoom, Skype, Google Meet
      </li>
      <li>Используйте наушники для хорошего звука</li>
      <li>
        В случае технической ошибки или сбоя соединения используйте кнопку Выйти
        и повторное подключение
      </li>
      <hr />
      <div>
        Вам нужна помощь или есть вопросы по подключению? Наша{' '}
        <a
          href="https://telegram.me/DoTherapy_chat"
          target="_blank"
          style={{color: "blue"}}
          rel="noreferrer"
        >
          Служба поддержки
        </a>{' '}
        позаботится о вас!
      </div>
    </ul>
  </div>
);
export default PreparationHelp;
