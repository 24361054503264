import React from 'react';
import { Radio } from 'antd';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import cn from 'classnames';

import styles from './CustomRadioButton.module.scss';

export const CustomRadioButton: React.FC<RadioButtonProps> = ({
  value,
  children,
  className,
  disabled,
}) => (
  <Radio.Button
    className={cn(styles.radioButton, className)}
    disabled={disabled}
    value={value}
  >
    {children}
  </Radio.Button>
);

export default CustomRadioButton;
