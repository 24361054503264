const getUserMedia = async (constraints: MediaStreamConstraints) => {
  const stream = await navigator.mediaDevices.getUserMedia(constraints);

  return {
    permissions: {
      video: true,
      audio: true,
    },
    stream,
  };
};
export default getUserMedia;
