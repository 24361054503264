

function shuffle(array: any) {
  // eslint-disable-next-line no-plusplus
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function createRandomArray (min: number, max: number) {
  const numbers = [];
  // eslint-disable-next-line no-plusplus
  for (let i = min; i <= max; i++) {
    numbers.push(i);
  }
  shuffle(numbers);
  return numbers;
}

const creator = (item: any, classParent: any) => {
  const parent = document.createElement('div');
  parent.classList.add(`${classParent}`);
  parent.append(item.cloneNode(true));
  return parent;
};

const sortHeroCard = (cards: any, durationCoefficient: any, heroLines: any) => {
  const randomNumbers = createRandomArray(0, cards.length - 1);

  const line = document.createElement('div');
  line.classList.add('hero__line');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < randomNumbers.length; i++) {
    line.append(cards[randomNumbers[i]]);
  }
  line.style.animationDuration = `${cards.length * durationCoefficient}s`;

  const wrapper = creator(line, 'hero__line-wrap');
  const group = creator(wrapper, 'hero__line-group');
  group.append(wrapper.cloneNode(true));
  heroLines.prepend(group.cloneNode(true));
};

const moveHeroLines = (heroLines: any) => {
  if (heroLines) {
    if (window.matchMedia('(min-width: 768px)').matches) {
      const cards = heroLines.querySelectorAll('.hero-card');
      heroLines.innerHTML = '';
      sortHeroCard(cards, 15, heroLines);
      sortHeroCard(cards, 11, heroLines);
      sortHeroCard(cards, 10, heroLines);
    }
  }
};

export {moveHeroLines};
