import { ReactComponent as CameraOff } from '1_shared/assets/images/conference/video-recorder-off.svg';

import styles from './styles.module.scss';

const CameraOffIndicator = () => (
  <div className={styles.root}>
    <CameraOff style={{ stroke: 'black', width: 24, height: 24 }} />
    <div className={styles.text}>Камера собеседника отключена</div>
  </div>
);
export default CameraOffIndicator;
