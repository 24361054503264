import { ReactComponent as MicroOff } from '1_shared/assets/images/conference/microphone-off.svg';

import styles from '../CameraOffIndicator/styles.module.scss';

const MicroOffIndicator = () => (
  <div className={styles.root}>
    <MicroOff
      width={24}
      height={24}
      style={{ stroke: 'black', fill: 'none' }}
    />
    <div className={styles.text}>
      Микрофон собеседника отключен
    </div>
  </div>
);
export default MicroOffIndicator;
