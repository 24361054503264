import { KeyedMutator } from 'swr';
import useSWRMutation from 'swr/mutation';

import { ClientProfileSessionOutput } from '../../SessionsPc/api/interfaces/ClientProfileSessionOutput';
import { cancelSession, reminderSwitch } from '../api/tableService';

import { IHookActionsOutput } from './interfaces/IHookActionsOutput';
import { EReminderType } from '../../../1_shared/config/enums/EReminderType';

const useActionsSession = (
  mutate?: KeyedMutator<ClientProfileSessionOutput>,
): IHookActionsOutput => {
  const { trigger: cancelTr } = useSWRMutation(
    '/ss/order/v2/cancel',
    cancelSession,
  );
  const { trigger: reminderSwitchTr } = useSWRMutation(
    '/ss/reminder/switch',
    reminderSwitch,
  );

  const cancelSessionAction = async (sessionId: string) => {
    await cancelTr({ sessionId });
    if (mutate) {
      await mutate();
    }
  };

  const reminderSwitchAction = async (
    sessionId: string,
    type: EReminderType,
  ) => {
    await reminderSwitchTr({ sessionId, type });
    if (mutate) {
      await mutate();
    }
  };

  return { cancelSessionAction, reminderSwitchAction };
};

export default useActionsSession;
