import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ILocationState } from '../../../1_shared/config/interfaces/ILocationState';
import { Consultation } from '../../../4_widgets';

const ScrollToHashElement: React.FC<PropsWithChildren> = ({ children }) => {
  const { pathname, state: locationState, hash } = useLocation();
  const state = locationState as ILocationState;
  const [hashState, setHashState] = useState(hash);
  const removeHashCharacter = (str: string) => str.slice(1);
  const navigate = useNavigate();

  const onClose = (e: any) => {
    e.preventDefault();
    navigate(pathname.replace(hash, ''));
  };

  const scrollToHashElement = useCallback((hash: string) => {
    const element = document.getElementById(removeHashCharacter(hash));

    if (hash && !!element) {
      element?.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    }
  }, []);

  useEffect(() => {
    setHashState(state?.anchor || '');
  }, [state?.anchor]);

  window.onpopstate = () => {
    setTimeout(() => {
      scrollToHashElement(hashState);
    }, 200);
  };

  useEffect(() => {
    if (hashState || hash) {
      scrollToHashElement(hashState || hash);
    }
  }, [hash, hashState, scrollToHashElement, state]);

  return (
    <section>
      {children}
      <Consultation
        open={hash === '#application'}
        closable
        footer={null}
        onCancel={onClose}
      />
    </section>
  );
};

export default ScrollToHashElement;
