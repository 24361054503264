import { env } from '../../env';

export const apiLinksByEnv =
  env.NODE_ENV === 'development'
    ? env.REACT_APP_API_URL_DEV
    : env.REACT_APP_API_URL;

export const apiLinksForNotification =
  env.NODE_ENV === 'development'
    ? env.REACT_APP_API_URL_DEV
    : env.REACT_APP_SOCKET_URL;
