import { ISort } from './ISort';
import { ESortType } from '../../../4_widgets/SpecialistFilters/ui/SpecialistFilters';

export enum SessionType {
  PERSONAL_CONSULTATION = 'PERSONAL_CONSULTATION',
  SUPERVISION = 'SUPERVISION',
  GROUP = 'GROUP',
}

export interface ISpecialistListFilters {
  // experienceSortOrder?: ISort;
  // defaultSort?: boolean;
  sortRequest: {
    order?: 'ASC' | 'DESC';
    sortType: ESortType;
  };
  keyThemes: string[];
  workWith: string;
  experience: string;
  sex: string | null;
  slotsFilter: {
    localTimeFrom: string;
    localTimeTo: string;
    zoneOffset: string;
    dayType: string | null;
    date: string;
    localTime: string;
  };
  price: string;
  moneyFilter: {
    minMoneyRate: number | null;
    maxMoneyRate: number | null;
  };
  specialties: string;
  specShowType: string;
  surname?: string;
  sessionType: SessionType.SUPERVISION | null;
  workMethod: string[];
}

export interface ISpecialistListFiltersQuery {
  filter: ISpecialistListFilters;
}
