export enum EWellBeingStatus {
  NotMe = 'NOT_ME',
  Ok = 'I_AM_OK',
  Bad = 'I_AM_BAD',
  VeryBad = 'I_VERY_BAD',
}
export const wellBeingStatusMapping: Record<EWellBeingStatus, string> = {
  [EWellBeingStatus.NotMe]:
    'Я чувствую себя хорошо, и хочу посоветоваться о другом человеке',
  [EWellBeingStatus.Ok]: 'Я чувствую себя нормально, но мне нужна помощь',
  [EWellBeingStatus.Bad]: 'Я чувствую себя плохо',
  [EWellBeingStatus.VeryBad]: 'Я чувствую себя очень плохо',
};
