import { ReactComponent as Cardio } from '1_shared/assets/pictures/stack/icons/cardio.svg';
import { ReactComponent as Kids } from '1_shared/assets/pictures/stack/icons/kids.svg';
import { ReactComponent as Loss } from '1_shared/assets/pictures/stack/icons/loss.svg';
import { ReactComponent as Money } from '1_shared/assets/pictures/stack/icons/money.svg';
import { ReactComponent as Romance } from '1_shared/assets/pictures/stack/icons/romance.svg';
import { ReactComponent as Shield } from '1_shared/assets/pictures/stack/icons/shield.svg';
import { ReactComponent as Smileys } from '1_shared/assets/pictures/stack/icons/smileys.svg';
import { ReactComponent as UserRefresh } from '1_shared/assets/pictures/stack/icons/user-refresh.svg';

import 'app/styles/global/global.scss';
import './problem.scss';

const Problem = () => (
  <section className="problem">
    <div className="problem__container container">
      <span className="problem__suptitle suptitle">направления</span>
      <h2 className="problem__title section-title-big">
        Уделяем внимание <span>каждой</span> проблеме
      </h2>
      <ul className="problem__list">
        <li className="problem__item">
          <Romance width={49} height={49} />
          <p>Отношения</p>
        </li>
        <li className="problem__item">
          <Kids width={49} height={49} />
          <p>Дети, подростки</p>
        </li>
        <li className="problem__item">
          <UserRefresh width={49} height={49} />
          <p>Самооценка</p>
        </li>
        <li className="problem__item">
          <Shield width={49} height={49} />
          <p>Насилие</p>
        </li>
        <li className="problem__item">
          <Loss width={49} height={49} />
          <p>Утрата</p>
        </li>
        <li className="problem__item">
          <Money width={49} height={49} />
          <p>Финансы, карьера </p>
        </li>
        <li className="problem__item">
          <Cardio width={49} height={49} />
          <p>Здоровье и психосоматика</p>
        </li>
        <li className="problem__item">
          <Smileys width={49} height={49} />
          <p>Эмоциональное состояние</p>
        </li>
      </ul>
    </div>
  </section>
);

export default Problem;
