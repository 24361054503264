import BodyTech from '1_shared/assets/images/specialistPhotos/bodyTherapi.png';
import FiveMin from '1_shared/assets/images/specialistPhotos/fiveMinuts.png';
import FixSleep from '1_shared/assets/images/specialistPhotos/fixSleep.png';
import ChoosePsy from '1_shared/assets/images/specialistPhotos/howChoosePsy.png';
import TechExit from '1_shared/assets/images/specialistPhotos/techExit.png';
import TechLogo from '1_shared/assets/images/specialistPhotos/techLogoterapi.png';

import { IVideoItems } from './interfaces/IVideoItems';

export const videoMockb2c: IVideoItems[] = [
  {
    title: 'Как выбрать психолога?',
    duration: '2:31',
    src: 'https://storage.yandexcloud.net/dot-content/landing/b2c/240214_DoTherapy_%D0%92%D0%B0%D0%BB%D0%B5%D1%80%D0%B8%D1%8F%20%D0%9A%D1%83%D0%B7%D0%BD%D0%B5%D1%86%D0%BE%D0%B2%D0%B0%20Copy%2001.mp4',
    preview: ChoosePsy,
  },
  {
    title: 'Как за 5 минут привести себя в порядок?',
    duration: '5:09',
    src: 'https://storage.yandexcloud.net/dot-content/landing/b2c/2024-03-05%2016.19.38.mp4',
    preview: FiveMin,
  },
  {
    title: 'Что делать с бессоницей',
    duration: '1:59',
    src: 'https://storage.yandexcloud.net/dot-content/landing/b2c/231211_%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B8%CC%86%20%D0%9E%D0%B2%D1%87%D0%B0%D1%80%D0%BE%D0%B2_%D0%98%D0%BD%D1%81%D0%BE%D0%BC%D0%BD%D0%B8%D1%8F-Master_01.mp4',
    preview: FixSleep,
  },
  {
    title: 'Техника для возвращения к своему телу и чувствам ',
    duration: '2:00',
    src: 'https://storage.yandexcloud.net/dot-content/landing/b2c/231214_DoTherapy_%D0%95%D0%B2%D0%B3%D0%B5%D0%BD%D0%B8%D0%B8%CC%86%20%D0%93%D0%BE%D0%BB%D0%BE%D0%B2%D0%B8%D0%BD%D0%BE%D0%B2_%D0%A3%D0%BF%D1%80%D0%B0%D0%B6%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5-Master_01.mp4',
    preview: TechExit,
  },
  {
    title: 'Техника из логотерапии',
    duration: '1:01',
    src: 'https://storage.yandexcloud.net/dot-content/landing/b2c/240216_DoTherapy_%D0%A1%D0%B2%D0%B5%D1%82%D0%BB%D0%B0%D0%BD%D0%B0_%D0%91%D1%83%D1%80%D1%82%D0%BD%D0%B8%D0%BA_04-Master_01.mp4',
    preview: TechLogo,
  },
  {
    title: 'Техника из телесной терапии чтобы отдохнуть',
    duration: '0:30',
    src: 'https://storage.yandexcloud.net/dot-content/landing/b2c/240214_DoTherapy_%D0%92%D0%B0%D0%BB%D0%B5%D1%80%D0%B8%D1%8F%20%D0%9A%D1%83%D0%B7%D0%BD%D0%B5%D1%86%D0%BE%D0%B2%D0%B0_02-Master_01.mp4',
    preview: BodyTech,
  },
];
