import styles from "./ModalCancelSession.module.scss";
import { Modal } from 'antd';
import { Typography } from '../../../1_shared/ui';
import { CustomButton } from '../../../1_shared/ui/CustomButton';
import IModalCancelSessionProps from './interfaces/IModalCancelSessionProps';


const ModalCancelSession = ({isOpen, closeModal, onCancel}: IModalCancelSessionProps ) => {
  const handleSubmit = () => {
    onCancel();
    closeModal();
  }

  return (
    <Modal
      open={isOpen}
      onCancel={closeModal}
      footer={null}
    >
      <Typography>Вы точно хотите отменить сесиию?</Typography>
      <div className={styles.controls}>
        <CustomButton
          type="secondary"
          className={styles.button}
          onClick={handleSubmit}
        >
          Да
        </CustomButton>
        <CustomButton className={styles.button} onClick={closeModal}>Нет</CustomButton>
      </div>
    </Modal>
  );
};

export default ModalCancelSession;