import * as VoxImplant from 'voximplant-websdk';

import getUserConstraints from './getUserConstraints';
import getUserMedia from './getUserMedia';

const initializationUserMedia = async (
  camera: any = null,
  microphone: any = null,
) => {
  const constraints = getUserConstraints(camera, microphone);

  if (camera === false) {
    constraints.video = false;
  }

  if (microphone === false) {
    constraints.audio = false;
  }

  const { permissions, stream } = await getUserMedia(constraints);
  const speakerStream =
    await VoxImplant.Hardware.AudioDeviceManager.get().getOutputDevices();

  const mediaStream: any = {
    audioStream: null,
    speakerStream,
    stream,
    videoStream: null,
  };

  if (camera !== false && permissions.video) {
    mediaStream.videoStream = stream.getVideoTracks();
  }

  if (microphone !== false && permissions.audio) {
    mediaStream.audioStream = stream.getAudioTracks();
  }

  return mediaStream;
};
export default initializationUserMedia;
