import { useEffect } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import useMessage from 'antd/es/message/useMessage';
import useSWRMutation from 'swr/mutation';

import { otherInformationFieldArray } from '../../../../1_shared/config/enums/EFieldArrayNames';
import { IOtherInformationSchema } from '../../../../1_shared/config/interfaces/IOtherInformation';
import { statusOptions } from '../../../../1_shared/constants/statusOptions';
import filterOption from '../../../../1_shared/helpers/filterOption';
import {
  Button as UiButton,
  Input,
  Select,
  Typography,
} from '../../../../1_shared/ui';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
import {
  userObjectConverterOtherInformation,
  userObjectConverterToNew,
} from '../../helpers/userObjectConverter';
import { editProfileOtherInformation } from '../../model/service/specEditService';

import styles from './OtherInformation.module.scss';

export const mergeObjects = (mainObject: any, newObject: any) => ({
  ...mainObject,
  ...Object.fromEntries(
    Object.entries(userObjectConverterToNew(newObject)).filter(el => !!el?.[1]),
  ),
});

const OtherInformation = () => {
  const { user, setSpecUser } = useAuthContext();
  const [messageApi, contextHolder] = useMessage();

  const methods = useForm({
    resolver: zodResolver(IOtherInformationSchema),
  });
  const { control, getValues, setValue, reset } = methods;
  const { fields, remove, append } = useFieldArray({
    control,
    // @ts-ignore
    name: otherInformationFieldArray,
  });

  const { trigger, isMutating, error } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileOtherInformation,
  );

  const onSubmit = async () => {
    const data = getValues();
    await trigger({
      ...data,
    });

    if (data && !error && setSpecUser) {
      setSpecUser(mergeObjects(user, data));
      localStorage.setItem('user', JSON.stringify(mergeObjects(user, data)));
      return messageApi.open({
        type: 'success',
        content: 'Данные профиля отредактированы!',
      });
    }

    return messageApi.open({
      type: 'warning',
      content: 'Сохранить изменения не удалось!',
    });
  };

  useEffect(() => {
    reset(userObjectConverterOtherInformation(user));
  }, [user]);

  return (
    <FormProvider {...methods}>
      <div className={styles.root}>
        <Typography type="title">Дополнительная информация</Typography>
        <div>
          <Typography type="description">
            Личная терапия/анализ (количество часов)
          </Typography>
          <Controller
            name="therapy_hours"
            control={control}
            defaultValue={getValues('therapy_hours')}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                min={0}
                placeholder="Личная терапия/анализ (количество часов)"
              />
            )}
          />
        </div>
        <div>
          <Typography type="description">
            Супервизия (количество часов)
          </Typography>
          <Controller
            name="supervision_hours"
            control={control}
            defaultValue={getValues('supervision_hours')}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                min={0}
                placeholder="Супервизия (количество часов)"
              />
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Статус (педагог, супервизор, профессор)
          </Typography>
          <Controller
            control={control}
            name="professional_status"
            render={({ field }) => (
              <Select
                allowClear
                options={statusOptions}
                showSearch
                filterOption={filterOption}
                {...field}
                onChange={value => {
                  if (value === undefined) {
                    setValue('professional_status', null);
                  } else {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />
        </div>
        <Typography type="textM">Достижения</Typography>
        {fields.map((field, index) => (
          <Controller
            control={control}
            name={`${otherInformationFieldArray}.${index}`}
            render={({ field }) => (
              <div className={styles.achievement}>
                <TextArea
                  {...field}
                  rows={4}
                  onChange={event =>
                    setValue(
                      `${otherInformationFieldArray}.${index}` as any,
                      event.target.value,
                    )
                  }
                />
                <Button
                  className={styles.removeButton}
                  type="link"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <CloseOutlined />
                </Button>
              </div>
            )}
          />
        ))}
        <Button
          type="link"
          className={styles.addButton}
          onClick={() => append('')}
        >
          <PlusOutlined className={styles.icon} />
          Добавить достижение
        </Button>
      </div>
      <UiButton
        className={styles.saveButton}
        type="primary"
        onClick={() => onSubmit()}
        disabled={isMutating}
      >
        Сохранить
      </UiButton>
      {contextHolder}
    </FormProvider>
  );
};

export default OtherInformation;
