import { TabsProps } from 'antd';

import { AnketaForm, WellBeingForm, Wishes } from '3_features';
import ContactInfo from '3_features/ContactInfo/ui/ContactInfo';

export const itemsForm: TabsProps['items'] = [
  {
    key: '1',
    label: 'Самочувствие',
    children: <WellBeingForm />,
  },
  {
    key: '2',
    label: 'Анкета',
    children: <AnketaForm />,
  },
  {
    key: '3',
    label: 'Сеанс',
    children: <Wishes />,
  },
  {
    key: '4',
    label: 'Контактная информация',
    children: <ContactInfo />,
  },
];
