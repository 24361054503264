import { useNavigate } from 'react-router-dom';

import DontsovaImg from '1_shared/assets/images/experts/dontsova@2x.png';
import DydychkinaImg from '1_shared/assets/images/experts/dydychkina@2x.png';
import GladkovskayaImg from '1_shared/assets/images/experts/gladkovskaya@2x.png';
import KuznetsovaImg from '1_shared/assets/images/experts/kuznetsova@2x.png';
import PopovaImg from '1_shared/assets/images/experts/popova@2x.png';
import ShtukaturevaImg from '1_shared/assets/images/experts/shtukatureva@2x.png';
import SipyaginImg from '1_shared/assets/images/experts/sipyagin@2x.png';
import VaismanImg from '1_shared/assets/images/experts/vaisman@2x.png';
import MipLogoImg from '1_shared/assets/images/mip-logo@2x.png';
import { ReactComponent as ExpertsAll } from '1_shared/assets/pictures/stack/icons/experts-all.svg';

import { RoutePath } from '../../../config/routes';

import 'app/styles/global/global.scss';
import './specialists.scss';

const Specialists = () => {
  const navigate = useNavigate();
  return (
    <section className="specialists" id="specialists">
      <div className="container specialists__container">
        <div className="specialists__inner">
          <div className="specialists__desc specialists__desc--top">
            <h2 className="specialists__title section-title">
              Более 150 <br /> специалистов
            </h2>
            <p className="specialists__subtitle">
              Отобранные экспертным сообществом профессионалы, подтвердившие
              свой опыт большим количеством успешных кейсов
            </p>
          </div>
          <div className="specialists__list-wrapper">
            <ul className="specialists__list">
              <li className="specialists__item">
                <img src={PopovaImg} alt="Татьяна Попова" />
                <h3>Татьяна Попова</h3>
                <p>
                  КПН, экзистенциально-гуманистическая терапия, арт-терапия,
                  когнитивно-поведенческая психотерапия; Лауреат национального
                  конкурса «Золотая психея»
                </p>
              </li>
              <li className="specialists__item">
                <img src={ShtukaturevaImg} alt="Светлана Штукарева" />
                <h3>Светлана Штукарева</h3>
                <p>
                  Руководитель Высшей Школы Логотерапии. Победитель
                  национального конкурса «Золотая Психея»
                </p>
              </li>
              <li className="specialists__item">
                <img src={GladkovskayaImg} alt="Елена Гладковская" />
                <h3>Елена Гладковская</h3>
                <p>
                  Экзистенциально-гуманистический терапевт, логотерапевт,
                  психолог-консультант, клинический психолог
                </p>
              </li>
              <li className="specialists__item">
                <img src={DydychkinaImg} alt="Ольга Дыдычкина" />
                <h3>Ольга Дыдычкина</h3>
                <p>
                  Психолог-консультант, арт-терапевт, магистр управления,
                  консультативный член Профессиональной Психотерапевтической
                  Лиги
                </p>
              </li>
              <li className="specialists__item">
                <img src={KuznetsovaImg} alt="Валерия Кузнецова" />
                <h3>Валерия Кузнецова</h3>
                <p>
                  Кандидат медицинских наук, научный сотрудник
                  Медико-Генетического Научного Центра, практикующий коуч,
                  арт-терапевт, старший научный сотрудник ЦНИИС
                </p>
              </li>
              <li className="specialists__item">
                <img src={SipyaginImg} alt="Дмитрий Сипягин" />
                <h3>Дмитрий Сипягин</h3>
                <p>
                  психоаналитик, психолог-консультант, Кандидат психологических
                  наук, Член международной психологической ассоциации
                </p>
              </li>
              <li className="specialists__item">
                <img src={VaismanImg} alt="Ирина Вайсман" />
                <h3>Ирина Вайсман</h3>
                <p>
                  Психоналитический психотерапевт, член Московской
                  психоаналитической ассоциации, Член Европейской ассоциации
                  развития психоанализа и психотерапии
                </p>
              </li>
              <li className="specialists__item">
                <img src={DontsovaImg} alt="Маргарита Донцова" />
                <h3>Маргарита Донцова</h3>
                <p>
                  Кандидат психологических наук, когнитивно-поведенческая
                  терапия, гештальт терапия, арт-терапия
                </p>
              </li>
              <li className="specialists__item">
                <button
                  className="specBtn"
                  type="button"
                  onClick={() =>
                    navigate(RoutePath.SPECIALISTS, {
                      state: { anchor: '#specialists' },
                    })
                  }
                  aria-label="Все специалисты"
                >
                  <ExpertsAll width={134} height={134} />
                </button>
              </li>
            </ul>
          </div>
          <div className="specialists__desc specialists__desc--bottom">
            <ul className="specialists__benefits">
              <li className="specialists__benefits-item specialists__benefits-item--exp">
                Опыт от&nbsp;5&nbsp;лет
              </li>
              <li className="specialists__benefits-item specialists__benefits-item--edu">
                Только высшее образование
              </li>
              <li className="specialists__benefits-item specialists__benefits-item--ru">
                Русскоговорящие
              </li>
            </ul>
            <button
              type="button"
              onClick={() =>
                navigate(RoutePath.SPECIALISTS, {
                  state: { anchor: '#specialists' },
                })
              }
              className="specialists__btn btn btn--violet"
            >
              показать всех специалистов
            </button>
            <div className="specialists__project">
              <h4 className="specialists__project-title">
                Проект Московского института психоанализа
              </h4>
              <img
                className="specialists__project-image"
                src={MipLogoImg}
                alt="Проект Московского института психоанализа"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Specialists;
