import { useEffect, useState } from 'react';
import useSWRMutation from 'swr/mutation';

import { ELoginStatus } from '../../../../1_shared/config/enums/ELoginStatus';
import { ERoles } from '../../../../1_shared/config/enums/ERoles';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import useLogin from '../../../LoginForm/model/useLogin';
import useRegister from '../../../LoginForm/model/useRegister';
import { changeNickName } from '../api/order.login.service';

import { IHookPhoneLoginOutput } from './interfaces/IHookPhoneLoginOutput';

const usePhoneLogin = (): IHookPhoneLoginOutput => {
  const [openCode, setOpenCode] = useState(false);
  const [status, setStatus] = useState<ELoginStatus>(ELoginStatus.None);
  const [isLogin, setIsLogin] = useState(true);
  const { login: loginAuth } = useAuthContext();
  const { login, errorTimerMessage, setErrorTimerMessage } = useLogin({
    status,
    setLoginStatus: setStatus,
  });
  const { register } = useRegister({
    status,
    setLoginStatus: setStatus,
  });

  const { trigger: nicknamePatch } = useSWRMutation(
    '/aim/client/nickname',
    changeNickName,
  );

  useEffect(() => {
    if (status === ELoginStatus.NotFound) {
      setIsLogin(false);
    }

    if (status === ELoginStatus.CodeSend) {
      setOpenCode(true);
    }
  }, [status]);

  const registerUser = async (phone: string, code?: string) => {
    await register({ login: phone, code, role: ERoles.Client });
  };

  const foundUser = async (phone: string, code?: string) => {
    await login({ login: phone, role: ERoles.Client, code }, false);
  };

  const updateNickname = async (name: string) => {
    await nicknamePatch({ nickname: name });
    if (loginAuth) {
      loginAuth(ERoles.Client);
    }
  };

  const handleChangePhone = async (value: string) => {
    if (value.match(/\+7\s\d{3}\s\d{3}\s\d{2}\s\d{2}/)) {
      await foundUser(value);
    }
    if (
      value.length === 10 &&
      value.match(/\d{3}\d{3}\d{2}\d{2}/) &&
      value.startsWith('9')
    ) {
      await foundUser(value);
    }
  };

  return {
    openCode,
    isLogin,
    status,
    errorTimerMessage,
    handleChangePhone,
    setOpenCode,
    setErrorTimerMessage,
    updateNickname,
    setIsLogin,
    foundUser,
    registerUser,
    setStatus,
  };
};

export default usePhoneLogin;
