import { FC } from 'react';
import { ArrowUpOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';

import IPageProps from './interfaces/IPageProps';

import styles from './Page.module.scss';

const Page: FC<IPageProps> = ({ children, withFloatButton = false }) => (
  <div className={styles.page}>
    {withFloatButton && (
      <FloatButton
        icon={<ArrowUpOutlined style={{ color: 'gray' }} />}
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
      />
    )}
    {children}
  </div>
);

export default Page;
