import { ReactComponent as ChatBubble } from '1_shared/assets/pictures/stack/icons/chat-bubble.svg';
import { ReactComponent as MessageLova } from '1_shared/assets/pictures/stack/icons/message-love.svg';
import { ReactComponent as UserCircle } from '1_shared/assets/pictures/stack/icons/user-circle.svg';
import { ReactComponent as UserGroup } from '1_shared/assets/pictures/stack/icons/user-group.svg';

import 'app/styles/global/global.scss';
import './directions.scss';

const DirectionsSingle = () => (
  <section className="directions">
    <div className="container directions__container">
      <div className="directions__inner">
        <span className="directions__suptitle suptitle">направления</span>
        <h2 className="directions__title section-title-big">
          <span>Индивидуальные</span> сессии с психологами
        </h2>
        <div className="directions__wrapper">
          <ul className="directions__list">
            <li className="directions__item">
              <UserGroup width={49} height={49} />
              <span>с психологом</span>
              <p>
                Чувство защищенности
                <br /> и поддержка
              </p>
            </li>
            <li className="directions__item">
              <UserCircle width={49} height={49} />
              <span>с психологом</span>
              <p>
                Глубинная проработка
                <br /> запроса
              </p>
            </li>
            <li className="directions__item">
              <ChatBubble width={49} height={49} />
              <span>с психологом</span>
              <p>
                Комфортный
                <br /> темп
                <br /> работы
              </p>
            </li>
            <li className="directions__item">
              <MessageLova width={49} height={49} />
              <span>с психологом</span>
              <p>
                Гибкое
                <br /> расписание
                <br /> встреч
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default DirectionsSingle;
