import React from 'react';

import { SpecialistAchievementsList, SpecialistExperienceData } from '../../../2_entities';
import { ISpecPersonCards } from '../../SpecialistPersonCard/ui/interfaces/ISpecPersonCards';

import styles from './SpecialistAchievementsCard.module.scss';

export const SpecialistAchievementsCard: React.FC<ISpecPersonCards> = ({
  spec,
}) => (
  <div className={styles.achievementsCard}>
    <SpecialistAchievementsList spec={spec} />
    <SpecialistExperienceData spec={spec} />
  </div>
);
