import { Content, Footer, Page } from '../../../1_shared/ui';
import { ProfileUser } from '../../../2_entities';
import { Header, PcSpecTabs } from '../../../4_widgets';

const PcSpec = () => (
  <Page>
    <Header />
    <Content>
      <ProfileUser />
    </Content>
    <PcSpecTabs />
    <Footer />
  </Page>
);

export default PcSpec;
