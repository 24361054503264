import { useNavigate } from 'react-router';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import styles from './EditProfileButton.module.scss';

const EditProfileButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      className={styles.editButton}
      type="text"
      onClick={() => navigate('/pc/edit-profile')}
    >
      <EditOutlined size={20} />
      Редактировать профиль
    </Button>
  );
};

export default EditProfileButton;
