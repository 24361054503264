import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Radio, UploadProps } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import useSWRMutation from 'swr/mutation';

import { ESex } from '../../../../1_shared/config/enums/ESex';
import {
  TUserEditInfoSchema,
  userEditInfoSchema,
} from '../../../../1_shared/config/validationSchemas/userInfoSchema';
import { phoneFormConvert } from '../../../../1_shared/lib/helpers/phoneConvert';
import {
  Button,
  DatePicker,
  Input,
  MaskInput,
  Typography,
  Upload,
} from '../../../../1_shared/ui';
import { CustomTypography } from '../../../../1_shared/ui/CustomTypography';
import {
  EPhotoType,
  uploadPhoto,
} from '../../../../4_widgets/FirstFormTabs/ui/FirstFormTabs';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
import { userObjectConverterCredentials } from '../../helpers/userObjectConverter';
import { getMedia } from '../../model/getMedia';
import { removeFileHandler } from '../../model/removeFIleHandler';
import { editProfileCredentials } from '../../model/service/specEditService';
import { mergeObjects } from '../OtherInformation/OtherInformation';

import styles from './SpecialistEditProfileCredentials.module.scss';

const SpecialistEditProfileCredentials = () => {
  const [messageApi, contextHolder] = useMessage();
  const { setSpecUser } = useAuthContext();
  const user = JSON.parse(String(localStorage.getItem('user')));

  const methods = useForm<TUserEditInfoSchema>({
    resolver: zodResolver(userEditInfoSchema),
  });
  const {
    control,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
  } = methods;
  watch();

  const { trigger, isMutating, error } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileCredentials,
  );

  const onSubmit = async (data: TUserEditInfoSchema) => {
    await trigger({
      ...data,
      date_of_birth: dayjs(data?.date_of_birth).isValid()
        ? dayjs(data?.date_of_birth).unix()
        : data?.date_of_birth,
      phone: !data?.phone
        ? data?.phone
        : data?.phone.replace('+', '').split(' ').join(''),
      media: data?.media,
      avatar: undefined,
      sex: data?.sex.toLowerCase(),
    });

    if (data && !error && setSpecUser) {
      setSpecUser(mergeObjects(user, data));
      localStorage.setItem('user', JSON.stringify(mergeObjects(user, data)));
      return messageApi.open({
        type: 'success',
        content: 'Данные профиля отредактированы!',
      });
    }

    return messageApi.open({
      type: 'warning',
      content: 'Сохранить изменения не удалось!',
    });
  };

  const handleMediaUpload = async (file: RcFile, type = EPhotoType.AVATAR) => {
    const response = user ? await uploadPhoto(file, user.id, type) : null;
    if (response) {
      try {
        if (response.length)
          setValue('media', [...getValues('media'), ...response]);
        else setValue('media', [...getValues('media'), response]);
        if (setSpecUser) {
          setSpecUser(mergeObjects(user, getValues()));
          messageApi.open({
            type: 'success',
            content: 'Фотография загружена!',
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const uploadProps: UploadProps = useMemo(
    () => ({
      maxCount: 1,
      accept: '.png, .jpg, .jpeg, .webp',
      fileList: getMedia(getValues('media'), true),
      listType: 'picture-card',
      onRemove: event =>
        removeFileHandler(
          event,
          getValues('media'),
          setValue,
          'media',
          user,
          setSpecUser,
          messageApi,
        ),
      beforeUpload: file => handleMediaUpload(file),
    }),
    [getValues('media')],
  );

  useEffect(() => {
    reset(userObjectConverterCredentials(user) as TUserEditInfoSchema);
  }, [reset]);

  return (
    <FormProvider {...methods}>
      <div className={styles.root}>
        <Typography type="title">Личные данные</Typography>
        <div className={styles.imagesWrap}>
          <Controller
            control={control}
            name="avatar"
            render={({ field: { onChange } }) => (
              <Upload
                {...uploadProps}
                customRequest={({ onSuccess }) => onSuccess && onSuccess('ok')}
                onChange={info => {
                  if (info.file.status === 'removed') {
                    onChange(null);
                  } else {
                    onChange(info.file);
                  }
                }}
              />
            )}
          />
          <Typography type="description">Фото</Typography>
        </div>
        <div>
          <Typography type="description">
            Имя<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="first_name"
            control={control}
            defaultValue={getValues('first_name')}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  placeholder="Имя"
                  status={errors.first_name ? 'error' : undefined}
                />
                {errors.first_name && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.first_name.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
        </div>
        <div>
          <Typography type="description">
            Фамилия<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="last_name"
            control={control}
            defaultValue={getValues('last_name')}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  placeholder="Фамилия"
                  status={errors.last_name ? 'error' : undefined}
                />
                {errors.last_name && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.last_name.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
        </div>
        <div>
          <Typography type="description">Отчество</Typography>
          <Controller
            name="patronymic"
            control={control}
            defaultValue={getValues('patronymic')}
            render={({ field }) => <Input {...field} placeholder="Отчество" />}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            E-mail адрес<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="email"
            control={control}
            defaultValue={getValues('email')}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  placeholder="e-mail"
                  status={errors.email ? 'error' : undefined}
                />
                {errors.email && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.email.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Номер телефона<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="phone"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <MaskInput
                  size="middle"
                  value={value}
                  autocomplete="on"
                  onChange={e => {
                    const maskPhone = phoneFormConvert(e.target.value);
                    onChange(maskPhone);
                  }}
                  mask="+7 999 999 99 99"
                  placeholder="Телефон"
                  status={errors.phone ? 'error' : undefined}
                />
                {errors.phone && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.phone.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">Пол</Typography>
          <Controller
            name="sex"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Radio.Group
                value={value}
                onChange={onChange}
                className={styles.sexGroup}
              >
                <Radio value={ESex.Male} className={styles.unselect}>
                  Мужской
                </Radio>
                <Radio value={ESex.Female} className={styles.unselect}>
                  Женский
                </Radio>
              </Radio.Group>
            )}
          />
        </div>
        <div className={styles.row}>
          <Typography type="description">
            Дата рождения<span className={styles.required_asterisk}>*</span>
          </Typography>
          <Controller
            name="date_of_birth"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <DatePicker
                  inputReadOnly
                  value={value ? dayjs(value) : null}
                  maxDate={dayjs()}
                  onChange={value => onChange(value)}
                  status={errors.date_of_birth ? 'error' : undefined}
                />
                {errors.date_of_birth && (
                  <CustomTypography type="description" className={styles.error}>
                    {errors.date_of_birth.message}
                  </CustomTypography>
                )}
              </>
            )}
          />
          <div className={styles.uploadWrap}>
            <Typography type="title">Ваши медиа-файлы</Typography>
            <Controller
              control={control}
              name="media"
              render={() => (
                <Upload
                  className="mediaWrapp"
                  accept=".png, .jpg, .jpeg, .webp"
                  fileList={getMedia(getValues('media'))}
                  customRequest={({ onSuccess }) =>
                    onSuccess && onSuccess('ok')
                  }
                  listType="picture-card"
                  onRemove={event =>
                    removeFileHandler(
                      event,
                      getValues('media'),
                      setValue,
                      'media',
                      user,
                      setSpecUser,
                      messageApi,
                    )
                  }
                  beforeUpload={file =>
                    handleMediaUpload(file, EPhotoType.FILE)
                  }
                />
              )}
            />
          </div>
        </div>
        <Button
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isMutating}
        >
          Сохранить
        </Button>
      </div>
      {contextHolder}
    </FormProvider>
  );
};

export default SpecialistEditProfileCredentials;
