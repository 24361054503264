import { mutation } from '../../../1_shared/api/apiInstance';

import SessionHistoryOutput from './interfaces/SessionHistoryOutput';
import SessionsHistoryInput from './interfaces/SessionsHistoryInput';

// SessionsHistoryInput
export const getSessionsHistory = mutation<
  SessionsHistoryInput,
  SessionHistoryOutput
>();
