import React from 'react';

import left from '../../../assets/pictures/left.svg';
import right from '../../../assets/pictures/right.svg';
import star from '../../../assets/pictures/star.svg';
import { Typography } from '../../index';

import { IAchievementProps } from './interfaces/IAchievementProps';

import styles from './Achievement.module.scss';

export const Achievement: React.FC<IAchievementProps> = ({
  experience,
  title,
  year,
}) => (
  <div className={styles.achievement}>
    <div className={styles.achievementContainer}>
      <img src={left} alt="left" />
      <div className={styles.centerOfAchievement}>
        {experience >= 10 && <img src={star} width={12} alt="star" />}
        <Typography component="h6" className={styles.achievementText}>
          {experience}
        </Typography>
      </div>
      <img src={right} alt="right" />
    </div>
    <Typography className={styles.title} type="subtitle">
      {year?.toUpperCase()} {title}
    </Typography>
  </div>
);
