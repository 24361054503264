import LogoMIPSvg from '../../../assets/pictures/MIP.svg';

import 'app/styles/global/global.scss';
import './statistics.scss';
import styles from '../../../../5_pages/CheckSession/CheckSession.module.scss';

const Statistics = () => (
  <section className="statistics">
    <div className="statistics__container container">
      <ul className="statistics__list">
        <div className="statistics__item">
          <a
            href="https://inpsycho.ru"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.mipLogo}
          >
            <source media="(max-width: 1023px)" srcSet={LogoMIPSvg} />
            <img src={LogoMIPSvg} alt="DoTherapy" />
          </a>
          <p>проект Московского института психоанализа</p>
        </div>
        <li className="statistics__item">
          <span className="statistics__number">1000+</span>
          <p>человек нашли своего психолога</p>
        </li>
        <li className="statistics__item">
          <span className="statistics__number">100+</span>
          <p>сертифицированных специалистов</p>
        </li>
        <li className="statistics__item">
          <span className="statistics__number">8 лет</span>
          <p>средний опыт практики</p>
        </li>
        <li className="statistics__item">
          <span className="statistics__number">2 из 100</span>
          <p>кандидатов становится психологом платформы</p>
        </li>
      </ul>
    </div>
  </section>
);

export default Statistics;
