import React from 'react';

import {
  About,
  DirectionsGroup,
  DirectionsSingle,
  Experts,
  Faq,
  Feedback,
  Footer,
  HeroMain,
  MeetingsGroup,
  MeetingsSingle,
  Problem,
  PsychologistsNear,
  Specialists,
  Statistics,
  Survey,
} from '1_shared/ui'; // Забираем нужную нам функцию из пакета, функции именуются - компонентами
import Page from '1_shared/ui/Page/ui/Page';
import { Header, Video } from '4_widgets';

import SurveyDiscount from '../../../1_shared/ui/SurveyReact/ui/SurveyDiscount';

const ClientMainPage: React.FC = () => (
  <Page>
    <Header />
    <HeroMain />
    <Statistics />
    <Problem />
    <Video />
    <Survey />
    <DirectionsSingle />
    <Specialists />
    <MeetingsSingle />
    <DirectionsGroup />
    <MeetingsGroup />
    <PsychologistsNear />
    <About />
    <Experts />
    <Feedback />
    {/* <Progs4Psychologists /> */}
    <Faq />
    <Footer />
  </Page>
);

export default ClientMainPage;
