import { useEffect, useState } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { Modal } from 'antd';
import { InputOTP } from 'antd-input-otp';

import { Button, Typography } from '1_shared/ui';

import { ELoginStatus } from '../../../1_shared/config/enums/ELoginStatus';
import { IApplicationForm } from '../../../4_widgets/OrderForm/ui/interface/IApplicationForm';

import { IPropsEnterCode } from './interfaces/IPropsEnterCode';

import styles from './EnterCode.module.scss';

const concateCode = (value: string[]) => value.join('');

const EnterCode = ({
  open,
  onClose,
  control,
  name,
  foundUser,
  phone,
  nickName,
  registerUser,
  isLogin,
  setStatus,
}: IPropsEnterCode) => {
  const [timer, setTimer] = useState<number>(80);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const onSubmit = async (value: string, onChange: (val?: string) => void) => {
    try {
      if (isLogin) {
        await foundUser(phone.replace('_', '') || '', value);
      } else {
        await registerUser(phone.replace('_', '') || '', value);
      }
      onChange(undefined);
      setErrorCode(null);
      onClose();
    } catch {
      setErrorCode('Введен не валидный код');
    }
  };

  const handlerAutoSubmit = (
    val: string[],
    field: ControllerRenderProps<IApplicationForm, keyof IApplicationForm>,
  ) => {
    const { value, onChange } = field;
    if (val?.length < 4) {
      onChange(value + val[3]);
      return onSubmit(value + val[3], onChange).then();
    }

    onChange(concateCode(val));
    return onSubmit(concateCode(val), onChange).then();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0 && open) {
        setTimer(prev => prev - 1);
      }
    }, 1000);

    if (timer <= 0) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [open, timer]);

  const onCloseHandler = (): void => {
    setErrorCode(null);
    onClose();
  };

  return (
    <Modal centered open={open} onCancel={onCloseHandler} footer={null}>
      <div className={styles.root}>
        <Typography type="title">Введите код из СМС</Typography>
        <Controller
          control={control}
          name={name as keyof IApplicationForm}
          render={({ field: { value, onChange }, field }) => (
            <div className={styles.wrappInput}>
              <div className={styles.wrappCode}>
                <InputOTP
                  type="text"
                  inputType="numeric"
                  length={4}
                  autoComplete="one-time-code"
                  autoSubmit={val => handlerAutoSubmit(val, field)}
                  value={value ? value?.split('') : []}
                  onChange={val => {
                    onChange(concateCode(val));
                  }}
                />
              </div>
              {errorCode && (
                <Typography type="description">{errorCode}</Typography>
              )}
              <div className={styles.btns}>
                <div className={styles.repeatCode}>
                  <Button
                    type="secondary"
                    disabled={timer !== 0}
                    onClick={async () => {
                      setStatus(ELoginStatus.None);
                      if (isLogin) {
                        await foundUser(phone.replace('_', '') || '', value);
                      } else {
                        await registerUser(
                          phone.replace('_', '') || '',
                          nickName,
                          value,
                        );
                      }
                      setStatus(ELoginStatus.CodeSend);
                      setTimer(85);
                    }}
                  >
                    ОТПРАВИТЬ КОД ПОВТОРНО
                  </Button>

                  {timer > 0 && (
                    <Typography type="subtitle">{`Будет доступно черех ${timer}`}</Typography>
                  )}
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </Modal>
  );
};

export default EnterCode;
