import { specApiInstance } from '../../../1_shared/api/apiInstance';

const useDeleteSlot = (slotId: string, fetchEvents: () => void) => {
  const deleteSlot = async () => {
    await specApiInstance.delete(`/ss/slots/v1/${slotId}`);
    fetchEvents();
  };

  return { deleteSlot };
};

export default useDeleteSlot;
