import { UploadFile } from 'antd';

export const getMedia = (values: any[], needAvatar = false) => {
  if (needAvatar) {
    const mediaFileObject = values?.find((el: any) => el.isPrimary);
    return mediaFileObject
      ? ([
        {
          uid: mediaFileObject?.id,
          name: 'Фото',
          status: 'done',
          url:
            mediaFileObject?.mediaContentResponse?.mainMediaLink ||
            mediaFileObject?.primaryContent?.mainContentLink,
        },
      ] as UploadFile[])
      : [];
  }

  const mediaFileObject = values?.filter((el: any) => !el.isPrimary);
  return mediaFileObject?.length
    ? (mediaFileObject.map((el: any) => ({
      uid: el?.id,
      name: 'Медиа-файл',
      status: 'done',
      url:
        el?.mediaContentResponse?.mainMediaLink ||
        el?.primaryContent?.mainContentLink,
    })) as UploadFile[])
    : [];
};