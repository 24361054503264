import PaymentDefaultImg from '1_shared/assets/images/payment-details@2x.png';
import SpriteSvg from '1_shared/assets/pictures/stack/stack.svg';

import 'app/styles/global/global.scss';
import './order.scss';
import './form.scss';
import './form-checkbox.scss';

const OrderCurs = () => (
  <section className="order">
    <div className="order__container container">
      <h1 className="order__title">Запись на курс</h1>
      <div className="order__form form">
        <form action="#" method="post">
          <div className="form__row form__row--course">
            <span className="form__label">курс</span>
            <div className="form__field">
              <p>Нейропсихология и когнитивные расстройства</p>
            </div>
          </div>

          <div className="form__row form__row--course">
            {/* <label htmlFor="date" className="form__label">Начало курса</label> */}
            <div className="form__row-inner">
              <div className="form__field">
                <p>13 декабря</p>
              </div>
              <div className="form__prompt">
                <p>Два часа три раза в неделю. Пришлем напоминание</p>
              </div>
            </div>
          </div>

          <div className="form__row form__row--with-btn">
            {/* <label htmlFor="name" className="form__label">Имя или псевдоним</label> */}
            <div className="form__row-inner">
              <div className="form__field">
                <div className="form__field-wrap">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Иван Константинов"
                  />
                </div>
                <button className="form__btn-resolve" type="button">
                  Сгенерировать псеводним
                </button>
              </div>
              <div className="form__prompt">
                <p>
                  Можете указать псевдоним, если не хотите раскрывать имя. Ваши
                  данные строго конфиденциальны
                </p>
              </div>
            </div>
          </div>

          <div className="form__row">
            {/* <label htmlFor="email" className="form__label">Email</label> */}
            <div className="form__row-inner">
              <div className="form__field">
                <div className="form__field-wrap">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="hello@kexit.it"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form__row">
            {/* <label htmlFor="phone" className="form__label">Телефон</label> */}
            <div className="form__row-inner">
              <div className="form__field">
                <div className="form__field-wrap">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    autoComplete='on'
                    placeholder="+7 999 66-6699"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form__row">
            {/* <label htmlFor="promocode" className="form__label">Промокод</label> */}
            <div className="form__row-inner">
              <div className="form__field">
                <div className="form__field-wrap form__field-wrap--promo">
                  <input
                    type="text"
                    id="promocode"
                    name="promocode"
                    placeholder=""
                  />
                  <button className="form__promo-btn" type="button">
                    применить
                  </button>
                </div>
              </div>
              <div className="form__prompt">
                <p>
                  Код из подарочного сертификата <br /> тоже сюда
                </p>
              </div>
            </div>
          </div>

          <div className="form__row form__row--payment">
            <span className="form__label">Способ оплаты</span>
            <div className="form__radio-inner">
              <div className="form__radio-box">
                <div className="form__field">
                  <div className="form__radio">
                    <input
                      type="radio"
                      name="payment"
                      id="payment-card"
                      value="payment-card"
                    />
                    {/* <label htmlFor="payment-card">Картой</label> */}
                    <svg viewBox="0 0 87 12" width="87" height="12">
                      <use href={`${SpriteSvg}#payment-card`} />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="form__radio-box">
                <div className="form__field">
                  <div className="form__radio">
                    <input
                      type="radio"
                      name="payment"
                      id="tinkoff-pay"
                      value="tinkoff-pay"
                    />
                    {/* <label htmlFor="tinkoff-pay">Tinkoff Pay</label> */}
                    <svg viewBox="0 0 35 16" width="35" height="16">
                      <use href={`${SpriteSvg}#pay`} />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="form__radio-box">
                <div className="form__field">
                  <div className="form__radio">
                    <input
                      type="radio"
                      name="payment"
                      id="payment-shares"
                      value="payment-shares"
                      checked
                    />
                    {/* <label htmlFor="payment-shares">Долями</label> */}
                    <svg viewBox="0 0 77 12" width="77" height="12">
                      <use href={`${SpriteSvg}#payment-shares`} />
                    </svg>
                  </div>
                  <div className="form__payment-shares">
                    <span>4 платежа по 1200&nbsp;₽</span>
                    <img src={PaymentDefaultImg} alt="" />
                  </div>
                </div>
                <div className="form__prompt">
                  <p>
                    Оплатите 25% от стоимости покупки, а оставшиеся 3 части
                    спишутся автоматически с шагом в две недели. Без процентов и
                    комиссий, как обычная оплата картой
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="form__row form__row--consent">
            <span className="form__label">согласия</span>
            <div className="form__checkbox-inner">
              <div className="form__checkbox-box">
                <div className="form-checkbox">
                  <input type="checkbox" name="agreement" id="agreement" />
                  {/* <label htmlFor="agreement">Принять пользовательское соглашение и договор оферы</label> */}
                </div>
              </div>
              <div className="form__checkbox-box">
                <div className="form-checkbox">
                  <input type="checkbox" name="consent" id="consent" />
                  {/* <label htmlFor="consent">Согласие на обработку данных</label> */}
                </div>
              </div>
            </div>
          </div>

          <div className="form__row form__row--footer">
            <span className="form__label">Итого</span>
            <div className="form__row-inner">
              <span className="form__total">4 965 ₽</span>
              <button className="form__submit btn btn--violet" type="submit">
                Оплатить
              </button>
              <div className="form__prompt">
                <p>
                  После оплаты мы пришлем <br /> вам доступ на платформу
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
);

export default OrderCurs;
