import { Controller, useFormContext } from 'react-hook-form';
import { Radio } from 'antd';

import { Radio as CustomRadio, Typography } from '1_shared/ui';

import { EDayTime } from '../../../1_shared/config/enums/EDayTime';
import { EExpWithPsy } from '../../../1_shared/config/enums/EExpWithPsy';
import { IAnketaForm } from '../../../1_shared/config/interfaces/IAnketaForm';
import {
  DAY_TYPE_FILTER,
  SEX_FILTER,
} from '../../../1_shared/constants/filterEnums';

import styles from './Wishes.module.scss';

const Wishes = () => {
  const { control } = useFormContext<IAnketaForm>();
  return (
    <section className={styles.root}>
      <Typography type="title">Пожелания</Typography>
      <div className={styles.itemWrap}>
        <Typography className={styles.subtitle}>Удобные для вас дни</Typography>
        <Controller
          control={control}
          name="dayType"
          render={({ field: { value, onChange } }) => (
            <Radio.Group
              value={value}
              onChange={onChange}
              className={styles.radioGroup}
            >
              <CustomRadio value={null}>Не имеет значения</CustomRadio>
              <CustomRadio value={DAY_TYPE_FILTER.WEEKDAY}>Будни</CustomRadio>
              <CustomRadio value={DAY_TYPE_FILTER.WEEKEND}>
                Выходные
              </CustomRadio>
            </Radio.Group>
          )}
        />
      </div>
      <div className={styles.itemWrap}>
        <Typography className={styles.subtitle}>Удобное время</Typography>
        <Controller
          control={control}
          name="time"
          render={({ field: { value, onChange } }) => (
            <Radio.Group
              value={value}
              onChange={onChange}
              className={styles.radioGroup}
            >
              <CustomRadio value={EDayTime.Any}>Любое</CustomRadio>
              <CustomRadio value={EDayTime.Morning}>Утро</CustomRadio>
              <CustomRadio value={EDayTime.Day}>День</CustomRadio>
              <CustomRadio value={EDayTime.Evening}>Вечер</CustomRadio>
            </Radio.Group>
          )}
        />
      </div>
      <div className={styles.itemWrap}>
        <Typography className={styles.subtitle}>
          Был ли у вас опыт работы с психологом
        </Typography>
        <Controller
          control={control}
          name="haveExpWithPsy"
          render={({ field: { value, onChange } }) => (
            <Radio.Group
              value={value}
              onChange={onChange}
              className={styles.radioGroup}
            >
              <CustomRadio value={EExpWithPsy.No}>Нет</CustomRadio>
              <CustomRadio value={EExpWithPsy.ShortTerm}>
                Краткострочный
              </CustomRadio>
              <CustomRadio value={EExpWithPsy.LongTerm}>
                Долгосрочный
              </CustomRadio>
            </Radio.Group>
          )}
        />
      </div>
      <div className={styles.itemWrap}>
        <Typography className={styles.subtitle}>
          Предпочтительный пол психотерапевта
        </Typography>
        <Controller
          control={control}
          name="sex"
          render={({ field: { value, onChange } }) => (
            <Radio.Group
              value={value}
              onChange={onChange}
              className={styles.radioGroup}
            >
              <CustomRadio value={null}>Не важен</CustomRadio>
              <CustomRadio value={SEX_FILTER.FEMALE}>Женский</CustomRadio>
              <CustomRadio value={SEX_FILTER.MALE}>Мужской</CustomRadio>
            </Radio.Group>
          )}
        />
      </div>
    </section>
  );
};

export default Wishes;
