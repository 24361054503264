import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import dayjs from 'dayjs';
import useSWRMutation from 'swr/mutation';
import { z } from 'zod';

import { EEditProfileWorkFieldArrayName } from '../../../../1_shared/config/enums/EFieldArrayNames';
import { TInputArrayField } from '../../../../1_shared/config/types/TInputArrayField';
import { IWorkExperienceSchema } from '../../../../1_shared/config/validationSchemas/workExperienceSchema';
import { Button as UiButton, Typography } from '../../../../1_shared/ui';
import { CustomTypography } from '../../../../1_shared/ui/CustomTypography';
import SpecialistEditProfileCheckbox from '../../../../1_shared/ui/SpecialistEditProfile/SpecialistEditProfileCheckbox';
import SpecialistEditProfileInput from '../../../../1_shared/ui/SpecialistEditProfile/SpecialistEditProfileInput';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { saveLinkForOldBackend } from '../../constants/saveLinkForOldBackend';
import { userObjectConverterWorkExperience } from '../../helpers/userObjectConverter';
import { editProfileWorkExperience } from '../../model/service/specEditService';
import { mergeObjects } from '../OtherInformation/OtherInformation';

import styles from './SpecialistEditProfileWorkExperience.module.scss';

const arrayFieldNames: TInputArrayField[] = [
  { name: 'company', label: 'Компания', required: true },
  { name: 'position', label: 'Должность', required: true },
  { name: 'city', label: 'Город', required: true },
];

const dateArray: TInputArrayField[] = [
  { name: 'date_from', label: 'Период работы (год)', placeholder: 'с' },
  { name: 'date_to', placeholder: 'до' },
];

type IWorkExperience = z.infer<typeof IWorkExperienceSchema>;

const SpecialistEditProfileWorkExperience = () => {
  const { setSpecUser } = useAuthContext();
  const user = JSON.parse(String(localStorage.getItem('user')));
  const [messageApi, contextHolder] = useMessage();

  // TODO: типизация формы
  const methods = useForm<IWorkExperience>({
    resolver: zodResolver(IWorkExperienceSchema),
    defaultValues: userObjectConverterWorkExperience(user),
  });
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: EEditProfileWorkFieldArrayName,
  });
  watch();

  const { trigger, isMutating, error } = useSWRMutation(
    saveLinkForOldBackend,
    editProfileWorkExperience,
  );

  const getTime = (date: any) => (date ? dayjs(date) : null);

  // TODO: any заменить согласно типу формы
  const onSubmit = async (data: IWorkExperience) => {
    const modifyData = {
      job_experiences: data.job_experiences?.map((element: any) => ({
        ...element,
        date_from: dayjs(element?.date_from).isValid()
          ? dayjs(element?.date_from).add(3, 'hours').unix()
          : element?.date_from,
        dateFrom: dayjs(element?.date_from).isValid()
          ? `${dayjs(element?.date_from).add(3, 'hours').year()}`
          : element?.date_from,
        date_to: element?.is_present_time
          ? ''
          : getTime(element?.date_to)?.unix(),
        dateTo: element?.is_present_time
          ? ''
          : `${dayjs(element?.date_to).year()}`,
        is_present_time: !!element?.is_present_time,
        isPresentTime: !!element?.is_present_time,
        id: '',
      })),
      utc_offset: 3,
    };

    await trigger({ job_experiences_attributes: modifyData.job_experiences });

    if (data && !error && setSpecUser) {
      setSpecUser(mergeObjects(user, modifyData));
      localStorage.setItem('user', JSON.stringify(mergeObjects(user, modifyData)));
      return messageApi.open({
        type: 'success',
        content: 'Данные профиля отредактированы!',
      });
    }

    return messageApi.open({
      type: 'warning',
      content: 'Сохранить изменения не удалось!',
    });
  };

  // @ts-ignore
  return (
    <FormProvider {...methods}>
      <div className={styles.root}>
        <Typography type="title">Опыт работы</Typography>
        <div className={styles.wrapper}>
          {fields.map((field, index) => (
            <div key={field.id} className={styles.fieldWrapper}>
              <div className={styles.field}>
                <div className={styles.workExperience}>
                  {arrayFieldNames.map((customField: TInputArrayField) => (
                    <>
                      <SpecialistEditProfileInput
                        {...field}
                        label={customField.label}
                        name={`${EEditProfileWorkFieldArrayName}.${index}.${customField.name}`}
                        required={customField.required}
                        status={
                          (errors?.[EEditProfileWorkFieldArrayName] as any)?.[
                            index
                          ]?.[customField.name]
                            ? 'error'
                            : undefined
                        }
                      />
                      {(errors?.[EEditProfileWorkFieldArrayName] as any)?.[
                        index
                      ]?.[customField.name] && (
                        <CustomTypography
                          type="description"
                          className={styles.error}
                        >
                          {
                            (errors?.[EEditProfileWorkFieldArrayName] as any)?.[
                              index
                            ]?.[customField.name]?.message
                          }
                        </CustomTypography>
                      )}
                    </>
                  ))}
                  <div className={styles.dateBlock}>
                    <SpecialistEditProfileInput
                      {...field}
                      name={`${EEditProfileWorkFieldArrayName}.${index}.${dateArray[0].name}`}
                      label={dateArray[0].label}
                      placeholder={dateArray[0].placeholder}
                      type="number"
                      status={
                        (errors?.[EEditProfileWorkFieldArrayName] as any)?.[
                          index
                        ]?.[dateArray[0].name]
                          ? 'error'
                          : undefined
                      }
                    />
                    <div className={styles.text}>—</div>
                    <SpecialistEditProfileInput
                      {...field}
                      name={`${EEditProfileWorkFieldArrayName}.${index}.${dateArray[1].name}`}
                      label={dateArray[1].label}
                      placeholder={dateArray[1].placeholder}
                      disabled={methods.getValues(
                        `${EEditProfileWorkFieldArrayName}.${index}.is_present_time` as any,
                      )}
                      type="number"
                      status={
                        (errors?.[EEditProfileWorkFieldArrayName] as any)?.[
                          index
                        ]?.[dateArray[1].name]
                          ? 'error'
                          : undefined
                      }
                    />
                  </div>
                  {((errors?.[EEditProfileWorkFieldArrayName] as any)?.[
                    index
                  ]?.[dateArray[0].name] ||
                    (errors?.[EEditProfileWorkFieldArrayName] as any)?.[
                      index
                    ]?.[dateArray[1].name]) && (
                    <CustomTypography
                      type="description"
                      className={styles.error}
                    >
                      {(errors?.[EEditProfileWorkFieldArrayName] as any)?.[
                        index
                      ]?.[dateArray[0].name]?.message ||
                        (errors?.[EEditProfileWorkFieldArrayName] as any)?.[
                          index
                        ]?.[dateArray[1].name]?.message}
                    </CustomTypography>
                  )}
                  <SpecialistEditProfileCheckbox
                    name={`${EEditProfileWorkFieldArrayName}.${index}.is_present_time`}
                    label="Работаю в настоящий момент"
                  />
                </div>
                <Button
                  className={styles.removeButton}
                  type="link"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <CloseOutlined />
                </Button>
              </div>
            </div>
          ))}
        </div>
        <Button
          type="link"
          className={styles.addButton}
          onClick={() => append({ company: '', position: '', city: '' })}
        >
          <PlusOutlined className={styles.icon} />
          Добавить место работы
        </Button>
        {!!fields?.length && (
          <UiButton
            className={styles.saveButton}
            type="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isMutating}
          >
            Сохранить
          </UiButton>
        )}
      </div>
      {contextHolder}
    </FormProvider>
  );
};

export default SpecialistEditProfileWorkExperience;
