import 'app/styles/global/global.scss';
import './breadcrumbs.scss';

const Breadcrumbs2 = () => (
<div className="breadcrumbs">
  <div className="breadcrumbs__container container">
    <ul>
      <li><a href="/">DoTherapy</a></li>
      <li><a href="/">Каждому</a></li>
      <li><a href='/'>Специалисты для меня</a></li>
    </ul>
  </div>
</div>
);

export default Breadcrumbs2;