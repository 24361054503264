import { ReactComponent as ChatBubble } from '1_shared/assets/pictures/stack/icons/chat-bubble.svg';
import { ReactComponent as MessageLova } from '1_shared/assets/pictures/stack/icons/message-love.svg';
import { ReactComponent as UserCircle } from '1_shared/assets/pictures/stack/icons/user-circle.svg';
import { ReactComponent as UserGroup } from '1_shared/assets/pictures/stack/icons/user-group.svg';

import 'app/styles/global/global.scss';
import './directions.scss';

const DirectionsGroup = () => (
  <section className="directions">
    <div className="container directions__container">
      <div className="directions__inner">
        <span className="directions__suptitle suptitle">направления</span>
        <h2 className="directions__title section-title-big">
          <span>Групповые</span> программы
        </h2>
        <div className="directions__wrapper">
          <ul className="directions__list">
            <li className="directions__item">
              <UserGroup width={49} height={49} />
              <span>в группе</span>
              <p>
                Социальная поддержка
                <br /> и понимание
              </p>
            </li>
            <li className="directions__item">
              <UserCircle width={49} height={49} />
              <span>в группе</span>
              <p>
                Обучение через наблюдение
                <br /> и обмен опытом
              </p>
            </li>
            <li className="directions__item">
              <ChatBubble width={49} height={49} />
              <span>в группе</span>
              <p>Развитие коммуникативных навыков и эмпатии</p>
            </li>
            <li className="directions__item">
              <MessageLova width={49} height={49} />
              <span>в группе</span>
              <p>
                Исследование
                <br /> себя в безопасной
                <br /> среде
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default DirectionsGroup;
