import { saveAs } from 'file-saver';
import useSWR from 'swr';

import { mutation } from '../../../1_shared/api/apiInstance';
import { ERequestType } from '../../../1_shared/api/interfaces/ERequestType';
import { apiLinksByEnv } from '../../../1_shared/constants/apiLinksByEnv';
import { env } from '../../../env';

export const getCalendarEvent = mutation<null, any>(ERequestType.Get);
const fetcher = (url: string) =>
  fetch(url, {
    credentials: 'include', // Указывает, что cookies должны быть отправлены
  }).then(res => {
    if (!res.ok) throw new Error('Ошибка сети');
    return res.text(); // Получаем текст ICS файла
  });
const useGetCalendarEvent = () => {
  const { mutate } = useSWR('', fetcher);

  const getSessionCalendarEvent = async (slotId: string) => {
    try {
      const url = `${apiLinksByEnv}/ss/api/v1/session-util/by-slot/${slotId}/generate-ics`;
      const data = await fetcher(url);

      saveAs(
        new Blob([data], { type: 'text/calendar;charset=utf-8' }),
        'Запись на сессию.ics',
      );

      // @ts-ignore
      await mutate(url, data, false);
    } catch (error) {
      console.error('Ошибка при загрузке ICS файла:', (error as any).message);
    }
  };

  return getSessionCalendarEvent;
};

export default useGetCalendarEvent;
