import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Cardio } from '1_shared/assets/pictures/stack/icons/cardio.svg';
import { ReactComponent as Kids } from '1_shared/assets/pictures/stack/icons/kids.svg';
import { ReactComponent as Loss } from '1_shared/assets/pictures/stack/icons/loss.svg';
import { ReactComponent as Money } from '1_shared/assets/pictures/stack/icons/money.svg';
import { ReactComponent as Romance } from '1_shared/assets/pictures/stack/icons/romance.svg';
import { ReactComponent as Shield } from '1_shared/assets/pictures/stack/icons/shield.svg';
import { ReactComponent as Smileys } from '1_shared/assets/pictures/stack/icons/smileys.svg';
import { ReactComponent as UserRefresh } from '1_shared/assets/pictures/stack/icons/user-refresh.svg';

import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { RoutePath } from '../../../config/routes';
import { Typography } from '../../index';

import { moveHeroLines } from './hero-lines';

import 'app/styles/global/global.scss';
import './hero.scss';
import './hero-card.scss';

const HeroMain = () => {
  const { user } = useAuthContext();
  useEffect(() => {
    const heroLines = document.querySelector('#heroLines');
    moveHeroLines(heroLines);
  }, []);

  const handleApplication = () => {
    // @ts-ignore
    ym(337610319, 'reachGoal', 'open_consultation_form');
  };

  const handleLine = () => {
    // @ts-ignore
    ym(97788822, 'reachGoal', 'main_banner');
  };

  return (
    <section className="hero">
      <div className="hero__container container">
        <div className="hero__content">
          <h2 className="hero__title section-title-big">
            Ваш <span>личный</span> психолог рядом
          </h2>
          <p>
            Ведущие эксперты психологии в удобном онлайн-формате
          </p>
          <div className="find_specialist">
            <Link
              to={user ? RoutePath.SPECIALISTS : RoutePath.REACT_ANKETA}
              state={{ anchor: 'survey', actionTab: '2' }}
              className="hero__btn btn btn--violet"
              // @ts-ignore
              onClick={() => ym(97788822, 'reachGoal', 'anketa_podbor_click')}
            >
              Подобрать специалиста
            </Link>
          </div>
          <Typography type="bold">от 2 950 ₽</Typography><Typography type="description"> за сессию</Typography>
          {/* <a */}
          {/*  href="#application" */}
          {/*  onClick={handleApplication} */}
          {/*  className="hero__btn btn btn--light" */}
          {/* > */}
          {/*  Мне нужна помощь */}
          {/* </a> */}
        </div>

        <div className="hero__lines" id="heroLines">
          <div className="hero__line-group">
            <div className="hero__line-wrap">
              <div className="hero__line">
                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Romance width={24} height={24} />
                  <h3 className="hero-card__title">Отношения</h3>
                  <p>Как перестать раздражаться на родственников?</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <svg viewBox="0 0 49 49" width="24" height="24">
                    <Romance width={24} height={24} />
                  </svg>
                  <h3 className="hero-card__title">Отношения</h3>
                  <p>Не могу принять себя и свое тело</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Kids width={24} height={24} />
                  <h3 className="hero-card__title">Дети, подростки</h3>
                  <p>Мысли о будущем вгоняют меня в ужас</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Kids width={24} height={24} />
                  <h3 className="hero-card__title">Дети, подростки</h3>
                  <p>Близкие не воспринимают меня всерьез</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Shield width={24} height={24} />
                  <h3 className="hero-card__title">Насилие</h3>
                  <p>Как противостоять бытовому насилию/агрессии?</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Shield width={24} height={24} />
                  <h3 className="hero-card__title">Насилие</h3>
                  <p>Я не могу это забыть</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Loss width={24} height={24} />
                  <h3 className="hero-card__title">Утрата</h3>
                  <p>Как жить после смерти близкого?</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Loss width={24} height={24} />
                  <h3 className="hero-card__title">Утрата</h3>
                  <p>Не могу пережить развод и расставание</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Money width={24} height={24} />
                  <h3 className="hero-card__title">Финансы, карьера</h3>
                  <p>Перестала радовать работа, чувствую выгорание</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Money width={24} height={24} />
                  <h3 className="hero-card__title">Финансы, карьера</h3>
                  <p>Постоянно откладываю важное на потом</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Cardio width={24} height={24} />
                  <h3 className="hero-card__title">
                    Здоровье и&nbsp;психосоматика
                  </h3>
                  <p>Постоянно снятся кошмары</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Cardio width={24} height={24} />
                  <h3 className="hero-card__title">
                    Здоровье и&nbsp;психосоматика
                  </h3>
                  <p>Я не помню, когда высыпалась</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <UserRefresh width={24} height={24} />
                  <h3 className="hero-card__title">Самооценка</h3>
                  <p>Как говорить «нет» и отстаивать личные границы?</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <UserRefresh width={24} height={24} />
                  <h3 className="hero-card__title">Самооценка</h3>
                  <p>Часто чувствую себя не в своей тарелке</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Smileys width={24} height={24} />
                  <h3 className="hero-card__title">Эмоциональное состояние</h3>
                  <p>Не могу перестать переживать обо всем</p>
                </Link>

                <Link
                  to={RoutePath.SPECIALISTS}
                  className="hero-card hero-card--light"
                  onClick={handleLine}
                >
                  <Smileys width={24} height={24} />
                  <h3 className="hero-card__title">Эмоциональное состояние</h3>
                  <p>Меня ничего не радует и не интересует</p>
                </Link>

                {/* <a href="/" className="hero-card hero-card--image">
              <img src="images/image-2@1x.png" alt="О чувстве тревоги">
              <p>О чувстве тревоги</p>
            </a> */}

                {/* <a href="/" className="hero-card hero-card--image">
              <img src="images/image-1@1x.png" alt="Как засыпать быстро">
              <p>Как засыпать быстро</p>
            </a> */}

                {/* <a href="/" className="hero-card hero-card--image">
              <img src="images/image-3@1x.png" alt="Как засыпать быстро">
              <p>Как жить с постоянным выгоранием</p>
            </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroMain;
