import { useEffect, useState } from 'react';
import {Image} from "antd";
import cn from 'classnames';

import IPreviewGroupImageProps from "./interfaces/IPreviewGroupImageProps";

import styles from "./PreviewGroupImage.module.scss";

const { PreviewGroup } = Image;

const PreviewGroupImage = ({
  items,
  currentMedia,
  className,
  ...otherProps
}: IPreviewGroupImageProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const index = items.findIndex(item => item.id === currentMedia.id);
    setCurrentIndex(index !== -1 ? index : 0);
  }, [currentMedia, items]);

  return (
    <PreviewGroup
      items={items.map(item => item.mainMediaLink)}
      preview={{
        current: currentIndex,
        onChange: (index) => setCurrentIndex(index),
      }}
    >
      <Image
        src={currentMedia.previewLink}
        className={cn(styles.image, className)}
        wrapperClassName={styles.imageWrapper}
        {...otherProps}
      />
    </PreviewGroup>
  )
}

export default PreviewGroupImage;