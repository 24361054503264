import { specApiInstance } from '../../../1_shared/api/apiInstance';
import { apiLinksByEnv } from '../../../1_shared/constants/apiLinksByEnv';

export const deleteFile = async (uuid: string) => {
  try {
    const response = await specApiInstance.delete(
      `${apiLinksByEnv}/content/specialist-content/${uuid}`,
    );
    return response.data;
  } catch (error) {
    throw new Error('Delete file error');
    console.error(error);
  }
};