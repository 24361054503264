export enum EDayTime {
  Any = 'ANY',
  Morning = 'MORNING',
  Day = 'DAY',
  Evening = 'EVENING',
}
export const dayTimeMapping: Record<EDayTime, string> = {
  [EDayTime.Any]: 'Любое',
  [EDayTime.Morning]: 'Утро',
  [EDayTime.Day]: 'День',
  [EDayTime.Evening]: 'Вечер',
};
