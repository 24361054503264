import { Tabs } from '../../../1_shared/ui';
import { itemsTabs } from '../config/itemsTabs';

import styles from './PcSpecTabs.module.scss';

const PcSpecTabs = () => (
  <div className={styles.root}>
    <Tabs
      classNameTabs={styles.tabs}
      items={itemsTabs}
      defaultActiveTabIndex="1"
    />
  </div>
);

export default PcSpecTabs;
