import { IOption } from '../../../1_shared/config/interfaces/IOption';
import { EPeriod } from '../api/interfaces/EPeriod';

export const periodConfig: IOption<EPeriod>[] = [
  {
    label: 'Месяц',
    value: EPeriod.Month,
  },
  {
    label: 'Квартал',
    value: EPeriod.Quarter,
  },
  {
    label: 'Год',
    value: EPeriod.Year,
  },
];
