import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import useSWRMutation from 'swr/mutation';

import { getDictionaries } from '1_shared/api/dictionary';
import useSWRWithCache from '1_shared/api/lib/useSWRWithCache';
import { ISpecialistListFilters } from '1_shared/config/interfaces';
import { IOption } from '1_shared/config/interfaces/IOption';
import { IPagination } from '1_shared/config/interfaces/IPagination';
import { ISpecialistData } from '1_shared/config/interfaces/ISpecialistData';

import { IUseGetSpecListOutput } from './interfaces/IUseGetSpecListOutput';
import { getSpecialistList } from './specService';

export const defaultPagination = {
  pageNumber: 0,
  pageSize: 15,
};

const useGetSpecialistList = (): IUseGetSpecListOutput => {
  const { getValues } = useFormContext<ISpecialistListFilters>();
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [specList, setSpecList] = useState<ISpecialistData[]>([]);
  const [total, setTotal] = useState<number>(-1);
  const [filters, setFilters] = useState<ISpecialistListFilters | {}>({
    ...getValues(),
    sortRequest: { ...getValues('sortRequest'), order: 'ASC' },
  });
  const [isNewFilter, setIsNewFilter] = useState<boolean>(false);

  const { data: dictionary } = useSWRWithCache<IOption[]>(
    '/spec/specialities',
    getDictionaries,
  );

  const {
    data: specListData,
    trigger,
    isMutating: isLoading,
    reset,
  } = useSWRMutation('/spec/specialist/all/filtered', getSpecialistList);

  const specTemp = useMemo(
    () =>
      specListData?.content.map(el => ({
        ...el,
        specialities: dictionary
          ? el.specialities.map(
              spec =>
                dictionary?.find(
                  (option: IOption<string>) => option.value === spec,
                )?.label ?? '',
            )
          : [],
      })),
    [specListData, dictionary],
  );

  const viewMore = (): void => {
    if (specList?.length <= total) {
      setPagination(prev => ({
        ...prev,
        pageNumber:
          prev.pageNumber + 1 < Math.ceil(total / defaultPagination.pageSize)
            ? (specListData?.pageable.pageNumber || 0) + 1
            : prev.pageNumber,
      }));
    }
  };

  const getSpecs = async (newFilter = false) => {
    if (total === -1 || (!isLoading && specList?.length < total) || newFilter) {
      await trigger({
        filter: filters,
        pageRequest: pagination,
      });
    }
  };

  /** TODO: нужно упростить 79-111
   * useEffect для каждого из конкретных событий является проблемой в дальнейшем
   * Нужно упростить логику хука до простых действий
   * Фильтр при изменении своего состояния и соответствия условиям при которых запрос может быть выполнен
   * должен триггерить запрос который будет изменять массив специалистов только 1 раз
   * */
  useEffect(() => {
    (async () => {
      // когда specList пустой или пока мы не отобразим всех специалистов
      await getSpecs();
    })();
  }, [pagination]);

  useEffect(() => {
    (async () => {
      setIsNewFilter(true);
      // когда specList должен обновится из-за фильтра
      setPagination(defaultPagination);
      reset();
      await getSpecs(true);
    })();
  }, [filters]);

  useEffect(() => {
    if (isNewFilter) {
      setSpecList(specTemp || []);
      setTotal(specListData?.pageable.total || 0);
      setPagination(defaultPagination);
      setIsNewFilter(false);
    }
    if (specTemp?.length) {
      setSpecList(prev => _.uniqBy([...(prev || []), ...specTemp], 'id'));
    }
    setTotal(specListData?.pageable.total || 0);
  }, [specTemp]);

  return {
    specList,
    isLoading,
    viewMore,
    setFilters,
    total,
    pagination,
    setPagination,
  };
};

export default useGetSpecialistList;
