export enum EExpWithPsy {
  No = 'NO',
  ShortTerm = 'SHORT_TERM',
  LongTerm = 'LONG_TERM',
}

export const expWithPsyMapping: Record<EExpWithPsy, string> = {
  [EExpWithPsy.No]: 'Нет',
  [EExpWithPsy.ShortTerm]: 'Краткосрочный',
  [EExpWithPsy.LongTerm]: 'Долгосрочный',
};
