import { NavigateFunction } from 'react-router';

import { createLocationString, getParamsWithoutUtm } from './locationHelpers';
import separateParams from './separateParams';

const changeParameterInRoute = (
  parameter: [string, unknown],
  location: any,
  navigate: NavigateFunction,
) => {
  // фильтрация уже существующих параметров для замены
  const filterParams = getParamsWithoutUtm(
    separateParams(location.search),
  ).filter(([key]) => key !== parameter?.[0]);

  // добавление параметра в строку
  if (parameter?.[1]) {
    const parameters = createLocationString([...filterParams, parameter]);

    return navigate(`${location.pathname}?${parameters}`);
  }

  // удаление параметра из строки
  const parameters = createLocationString([...filterParams]);

  return navigate(`${location.pathname}?${parameters}`);
};

export default changeParameterInRoute;
