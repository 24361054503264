import type { ProgressProps } from 'antd';
import { Progress } from 'antd';

const twoColors: ProgressProps['strokeColor'] = {
  '0%': '#108ee9',
  '100%': '#87d068',
};

const ProgressBar = (props: any) => (
  <Progress percent={props?.progress} status="active" strokeColor={twoColors} />
);

export default ProgressBar;
