export enum ConferenceEvents {
  MIC_OFF = 'MIC_OFF',
  MIC_ON = 'MIC_ON',
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED',
  CAM_OFF = 'CAM_OFF',
  CAM_ON = 'CAM_ON',
  SEND_STATUS = 'SEND_STATUS',
  ASK_STATUS = 'ASK_STATUS',
  DISCONNECT = 'DISCONNECT',
}

export type ConferenceStatus =
  | 'empty'
  | 'loading'
  | 'connected'
  | 'disconnected'
  | 'not-alone'
  | 'error';

const ConfStatusesMap: Record<ConferenceStatus, string> = {
  empty: 'Подключение к сервису...',
  loading: 'Подключение к сеансу...',
  connected: 'Ожидание собеседника...',
  disconnected: 'Соединение потеряно',
  error: 'Что-то пошло не так. Нет соединения с сервисом. Попробуйте подключиться позже',
  'not-alone': '',
};

export default ConfStatusesMap;

export type ConferenceTimeStatus = 'continue' | 'preEnd' | 'end' | 'postEnd';

export type DevicesStates = 'on' | 'off' | 'removed';
