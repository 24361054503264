import cn from 'classnames';
import dayjs from 'dayjs';

import { Typography } from '../../../1_shared/ui';
import useDeleteSlot from '../module/useDeleteSlot';

import { IScheduleEvents } from './interfaces/IScheduleEvents';

import styles from './ScheduleEvents.module.scss';

const ScheduleEvents = ({ event, fetchEvents, style }: IScheduleEvents) => {
  const { deleteSlot } = useDeleteSlot(event.id, fetchEvents);
  const isOld = event.start.getTime() < dayjs().toDate().getTime();
  const isDeleteble = !isOld && event.title === 'Свободный';
  return (
    <div
      className={cn(styles.root, {
        [styles.rootPrev]: isOld,
        [styles.rootDeleteble]: isDeleteble,
      })}
      style={{
        top: `${style?.top}%`,
        height: `${style?.height}%`,
      }}
    >
      <Typography>{event.title}</Typography>
      <Typography>{`${dayjs(event.start).format('HH:mm')}-${dayjs(event.end).format('HH:mm')}`}</Typography>
      <button
        type="button"
        onClick={isDeleteble ? deleteSlot : undefined}
        className={cn({ [styles.btn]: isDeleteble })}
      />
    </div>
  );
};

export default ScheduleEvents;
