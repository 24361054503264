import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as VoxImplant from 'voximplant-websdk';
import { Endpoint } from 'voximplant-websdk/Call/EndPoints/Endpoint';

import { AppRoute, RoutePath } from '1_shared/config/routes';
import {
  ConferenceEvents,
  ConferenceStatus,
  DevicesStates,
} from '1_shared/constants/conference';
import {
  getStoredUserMediaSettings,
  setStoredUserMediaSettings,
} from '1_shared/lib/helpers/conference/conference.helpers';
import getUserMediaDevices from '1_shared/lib/helpers/conference/getUserMediaDevices';
import initializationUserMedia from '1_shared/lib/helpers/conference/initializationUserMedia';
import useSocketConnection from '1_shared/lib/hooks/useSocketConnection';

import NotificationContext from '../../../app/module/lib/context/NotificationContext';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { errorrMessages } from '../config/errorrMessages';
import { ConferenceProps } from '../config/interfaces/ConferenceTypes';

import useConferenceSubscriptions from './useConferenceSubscriptions';

const useConferenceController = (props: ConferenceProps) => {
  const { isSessionStarted, slot } = props;
  const { messageApi } = useContext(NotificationContext);
  const { user, role } = useAuthContext();
  const voxImplant = VoxImplant.getInstance();

  const [devices, setDevices] = useState({
    camera: null,
    microphone: null,
    speaker: null,
  });
  const [selectedDevices, setSelectedDevices] = useState(
    getStoredUserMediaSettings(),
  );
  const [lockedDevices, setLockedDevices] = useState({
    speaker: false,
    microphone: false,
    camera: false,
  });
  const [loading, setLoading] = useState(true);
  const [currentCall, setCurrentCall] = useState<any>(null);
  const [endpoints, setEndpoints] = useState<Endpoint[]>([]);
  const [participantVideo, setParticipantVideo] =
    useState<DevicesStates>('removed');
  const [participantVoice, setParticipantVoice] =
    useState<DevicesStates>('off');
  const [status, setStatus] = useState<ConferenceStatus>('empty');
  const videoElement = useRef<HTMLMediaElement>(null);
  const [remoteUser, setRemoteUser] = useState<any>(null);

  const client = useSocketConnection();

  const sendMessage = useCallback(
    (event: ConferenceEvents) => {
      client.sendMessage(event);
    },
    [client],
  );

  const sendStatus = useCallback(
    () => client.sendStatus(ConferenceEvents.SEND_STATUS, lockedDevices),
    [client, lockedDevices, remoteUser],
  );

  // const getStatus = (status: any) => {
  //   if (status) {
  //     setParticipantVoice(!status.microphone ? 'on' : 'removed');
  //     setParticipantVideo(status.camera ? 'removed' : 'on');
  //   }
  // };

  const eventsInterceptionMap: Record<ConferenceEvents, any> = {
    MIC_OFF: () => setParticipantVoice('removed'),
    MIC_ON: () => setParticipantVoice('off'),
    CONNECTING: () => console.log('user connecting'),
    ASK_STATUS: () => {
      console.log('status asked');
      sendStatus();
    },
    CONNECTED: () => {
      console.log('user connected');
      sendStatus();
    },
    CAM_OFF: () => {
      console.log('camera off');
      setParticipantVideo('removed');
    },
    CAM_ON: () => {
      console.log('camera on');
      setParticipantVideo('on');
    },
    SEND_STATUS: () => console.log('status get'),
    DISCONNECT: () => {
      setParticipantVideo('removed');
      setParticipantVoice('removed');
      setRemoteUser(null);
    },
  };

  const interceptSocket = (message: {
    user: any;
    event: ConferenceEvents;
    status?: any;
  }) => {
    if (message.user?.id && user && message.user.id !== user?.id) {
      if (message.event === ConferenceEvents.SEND_STATUS) {
        setRemoteUser(message.user);
      } else {
        eventsInterceptionMap[message.event]();
      }
      if (
        message.event === ConferenceEvents.CONNECTING ||
        message.event === ConferenceEvents.CONNECTED
      ) {
        setRemoteUser(message.user);
      }
    }
  };

  const subscribeToEvents = () => {
    sendMessage(ConferenceEvents.CONNECTING);
  };

  const subscriptions = useConferenceSubscriptions(
    voxImplant,
    currentCall,
    setCurrentCall,
    participantVoice,
    participantVideo,
    !lockedDevices.camera,
    setParticipantVideo,
    setParticipantVoice,
    endpoints,
    setEndpoints,
    setStatus,
    sendMessage,
  );

  const onMicroChange = () => {
    if (!loading) {
      if (selectedDevices.microphone && !lockedDevices.microphone) {
        (async () => {
          const { audioStream } = await initializationUserMedia(
            false,
            selectedDevices.microphone,
          );

          window.localStream = new MediaStream([
            ...window.localStream.getVideoTracks(),
            ...audioStream,
          ]);
        })();

        if (currentCall) {
          currentCall.unmuteMicrophone();
        }
        sendMessage(ConferenceEvents.MIC_ON);
      } else {
        const audioStream = window.localStream.getAudioTracks();

        audioStream.forEach(track => {
          track.enabled = true;
        });

        if (currentCall) {
          currentCall.muteMicrophone();
        }
        sendMessage(ConferenceEvents.MIC_OFF);
      }
    }
  };

  const cameraOn = async () => {
    const { stream, videoStream } = await initializationUserMedia(
      selectedDevices.camera,
      false,
    );

    if (videoElement.current) videoElement.current.srcObject = stream;

    window.localStream = new MediaStream([
      ...window.localStream.getAudioTracks(),
      ...videoStream,
    ]);

    videoStream.forEach((track: any) => {
      track.enabled = true;
    });

    if (currentCall) {
      currentCall.sendVideo(true);
      await voxImplant.showLocalVideo(true);
    }
    sendMessage(ConferenceEvents.CAM_ON);
  };

  const cameraOff = async () => {
    const videoStream = window.localStream.getVideoTracks();

    (videoElement.current?.srcObject as MediaStream)
      ?.getVideoTracks()
      .forEach((track: MediaStreamTrack) => {
        track.stop();
        (videoElement.current?.srcObject as MediaStream)?.removeTrack(track);
      });
    videoStream.forEach(track => track.stop());

    if (currentCall) {
      currentCall.sendVideo(false);
      await voxImplant.showLocalVideo(false);
    }
    sendMessage(ConferenceEvents.CAM_OFF);
  };

  const onCameraChange = () => {
    if (!loading) {
      if (selectedDevices.camera && !lockedDevices.camera) {
        (async () => await cameraOn())();
      } else {
        (async () => await cameraOff())();
      }
    }
  };

  const connect = async () => {
    console.log(1);
    const devices: any = {};
    const selectedDevices: any = {};
    let response;

    for (const key of ['speaker', 'camera', 'microphone']) {
      try {
        response = await getUserMediaDevices(key, selectedDevices);

        const [first, second] = response;
        devices[key] = first;
        selectedDevices[key] = second;
      } catch (error) {
        console.log(error);
        // setLockedDevices(prev => {
        //   return { ...prev, [key]: true };
        // });
        if (errorrMessages[key]) {
          messageApi?.open({
            type: 'info',
            content: errorrMessages[key],
          });
        }
      }
    }

    const isWebRTCSupported = voxImplant.isRTCsupported();

    if (isWebRTCSupported) {
      const { stream } = await initializationUserMedia(
        selectedDevices.camera || false,
        selectedDevices.microphone,
      );
      stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      window.localStream = stream;

      if (user && !voxImplant.alreadyInitialized && isSessionStarted) {
        voxImplant.on(VoxImplant.Events.AuthResult, subscriptions.onAuthResult);
        voxImplant.on(
          VoxImplant.Events.ConnectionClosed,
          subscriptions.onConnectionClosed,
        );
        voxImplant.on(
          VoxImplant.Events.ConnectionEstablished,
          subscriptions.onConnectionEstablished,
        );
        voxImplant.on(
          VoxImplant.Events.ConnectionFailed,
          subscriptions.onConnectionFailed,
        );
        voxImplant.on(VoxImplant.Events.SDKReady, subscriptions.onSdkReady);

        try {
          await voxImplant.init({
            node: VoxImplant.ConnectionNode.NODE_4,
            localVideoContainerId: 'videoContainerSelf',
            remoteVideoContainerId: 'videoContainerCompanion',
          });
          await voxImplant.connect();
        } catch (error) {
          console.log(error);
        }
      }
    }

    setDevices({ ...devices });
    setSelectedDevices({ ...selectedDevices });
    setStoredUserMediaSettings(selectedDevices);
    if (currentCall) {
      currentCall.sendVideo(true);
      await voxImplant.showLocalVideo(false);
    }

    setLoading(false);
  };

  //
  // const onSpeakerChange = useCallback(() => {
  //   if (currentCall) {
  //     if (selectedDevices.speaker && !lockedDevices.speaker) {
  //       (async () => {
  //         const audioDevices =
  //           await VoxImplant.Hardware.AudioDeviceManager.get().getOutputDevices();
  //         const currentSpeaker = audioDevices.find(
  //           (speaker) => speaker.name === selectedDevices.speaker
  //         );
  //
  //         const audioParams = {
  //           outputId: String(currentSpeaker.id),
  //         };
  //
  //         await VoxImplant.Hardware.AudioDeviceManager.get().setCallAudioSettings(
  //           currentCall,
  //           audioParams
  //         );
  //       })();
  //
  //       currentCall.unmutePlayback();
  //     } else {
  //       currentCall.mutePlayback();
  //     }
  //   }
  // }, [currentCall, loading, selectedDevices, lockedDevices]);

  const onEnd = () => {
    setLoading(true);
    window.localStream?.getTracks().forEach(track => {
      track.stop();
    });
    window.localStream?.getAudioTracks().forEach(track => {
      track.enabled = false;
    });
    window.localStream?.getVideoTracks().forEach(track => {
      track.stop();
    });

    if (currentCall) {
      setParticipantVoice('off');
      setParticipantVideo('on');
      setRemoteUser(null);
      sendMessage(ConferenceEvents.DISCONNECT);
      client.unsub();
      currentCall.hangup();
      setCurrentCall(null);
    }

    window.location.href = RoutePath[`${role}_CABINET` as AppRoute];
  };

  // const shareScreen = useCallback(() => {
  //   currentCall.shareScreen(true, true);
  // }, [currentCall]);
  //
  // const stopSharing = useCallback(() => {
  //   currentCall.stopSharingScreen();
  // }, [currentCall]);

  const onLockedDevicesChange = (devices: any, toggledDevice: string) => {
    if (toggledDevice === 'camera') {
      sendMessage(
        devices.camera ? ConferenceEvents.CAM_ON : ConferenceEvents.CAM_OFF,
      );
    }
    if (toggledDevice === 'microphone') {
      sendMessage(
        devices.microphone ? ConferenceEvents.MIC_ON : ConferenceEvents.MIC_OFF,
      );
    }
    setLockedDevices({ ...devices, [toggledDevice]: !devices[toggledDevice] });
  };

  const onSelectedDevicesChange = (selected: string) => {
    setSelectedDevices(selected);
  };

  useEffect(() => {
    if (slot?.id && user) client.connectWS(slot.id, interceptSocket);
  }, [client, interceptSocket, slot, user]);

  useEffect(() => {
    if (status === 'not-alone') {
      if (!lockedDevices.camera) {
        cameraOn();
      } else {
        cameraOff();
      }
    }
  }, [status, lockedDevices.camera]);

  useEffect(() => {
    if (user && client.client.connected) {
      (async () => await connect())();
    }
  }, [user, isSessionStarted, voxImplant, client.client.connected]);

  useEffect(() => {
    if (status === 'not-alone') {
      sendMessage(
        lockedDevices.microphone
          ? ConferenceEvents.MIC_OFF
          : ConferenceEvents.MIC_ON,
      );
      sendMessage(
        lockedDevices.camera
          ? ConferenceEvents.CAM_OFF
          : ConferenceEvents.CAM_ON,
      );
    }
  }, [status]);

  // useEffect(() => {
  //   onSpeakerChange();
  // }, [selectedDevices.speaker, lockedDevices.speaker, loading]);

  useEffect(() => {
    onMicroChange();
  }, [selectedDevices.microphone, lockedDevices.microphone, loading]);

  useEffect(() => {
    onCameraChange();
  }, [selectedDevices.camera, lockedDevices.camera, loading]);

  return {
    loading,
    connect,
    videoElement,
    onEnd,
    remoteMedia: {
      video: participantVideo,
      voice: participantVoice,
    },
    endpoints,
    status,
    subscribeToEvents,
    remoteUser,
    devices: {
      devices,
      selectedDevices,
      lockedDevices,
    },
    onSelectedDevicesChange,
    onLockedDevicesChange,
    isConnectedToWS: client.client.connected,
  };
};
export default useConferenceController;
