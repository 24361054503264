import { useMemo } from 'react';

import { getDictionaries } from '../../../../../1_shared/api/dictionary';
import useSWRWithCache from '../../../../../1_shared/api/lib/useSWRWithCache';
import { IOption } from '../../../../../1_shared/config/interfaces/IOption';

const useSpecialistEditProfileMainInfo = () => {
  const { data: workWithOptions } = useSWRWithCache<IOption[]>(
    '/spec/work-withs',
    getDictionaries,
  );

  const { data: keyThemesOptions } = useSWRWithCache<IOption[]>(
    '/spec/key-themes',
    getDictionaries,
  );

  const { data: specialitiesOptions } = useSWRWithCache<IOption[]>(
    '/spec/specialities',
    getDictionaries,
  );

  const { data: dictionaryMethods } = useSWRWithCache<IOption[]>(
    '/spec/work-methods',
    getDictionaries,
  );

  const workWithMemo = useMemo(
    () =>
      workWithOptions?.map((el: IOption) => ({
        label: el.label,
        value: el.id,
      })),
    [workWithOptions],
  );

  const workMethodsMemo = useMemo(
    () =>
      dictionaryMethods?.map((el: IOption) => ({
        label: el.label,
        value: el.id,
      })),
    [dictionaryMethods],
  );

  const specMemo = useMemo(
    () =>
      specialitiesOptions?.map((el: IOption) => ({
        label: el.label,
        value: el.id,
      })),
    [specialitiesOptions],
  );

  const keyThemasMemo = useMemo(
    () =>
      keyThemesOptions?.map((el: IOption) => ({
        label: el.label,
        value: el.id,
      })),
    [keyThemesOptions],
  );

  return { workWithMemo, workMethodsMemo, specMemo, keyThemasMemo };
};

export default useSpecialistEditProfileMainInfo;
