import React from 'react';

import { declination, yearTitles } from '1_shared/helpers/declination';

import { Achievement } from '../../../1_shared/ui/Achievement';
import { ISpecPersonCards } from '../../../4_widgets/SpecialistPersonCard/ui/interfaces/ISpecPersonCards';

import styles from './SpecialistAchievementList.module.scss';

const SpecialistAchievementsList: React.FC<ISpecPersonCards> = ({ spec }) => (
  <div className={styles.achievementsCardsList}>
    <Achievement
      title="С DOTHERAPY"
      experience={Number(spec.yearsWithDoTherapy)}
      year={declination(Number(spec.yearsWithDoTherapy), yearTitles)}
    />
    <Achievement
      title="В ТЕРАПИИ"
      experience={Number(spec.experience)}
      year={declination(Number(spec.experience), yearTitles)}
    />
  </div>
);

export default SpecialistAchievementsList;
