import { parseCookies, setCookie } from 'nookies';

export const setStoredUserMediaSettings = (userMediaDevices: MediaDevices) => {
  setCookie(null, 'user.media_devices', JSON.stringify(userMediaDevices), {
    maxAge: 60 * 60 * 24 * 30 * 6,
    path: '/',
  });
};

export const setStoredUserLockedMediaSettings = (
  lockedMediaDevices: MediaDevices,
) => {
  setCookie(
    null,
    'user.locked_media_devices',
    JSON.stringify(lockedMediaDevices),
    {
      maxAge: 60 * 60 * 24 * 30 * 6,
      path: '/',
    },
  );
};

export const getStoredUserMediaSettings = () => {
  const cookies = parseCookies();
  const userMediaDevicesCookie = cookies['user.media_devices'];

  if (userMediaDevicesCookie) {
    return JSON.parse(userMediaDevicesCookie);
  }

  return {
    camera: null,
    microphone: null,
    speaker: null,
  };
};

export const getStoredUserLockedMediaSettings = () => {
  const cookies = parseCookies();
  const userLockedMediaDevicesCookie = cookies['user.locked_media_devices'];

  if (userLockedMediaDevicesCookie) {
    return JSON.parse(userLockedMediaDevicesCookie);
  }
  return {
    camera: false,
    microphone: false,
    speaker: false,
  };
};
