import cn from 'classnames';

import { Typography } from '1_shared/ui';

import { ISpecSpecializtionProps } from './interfaces/ISpecSpecializtionProps';

import styles from './SpecSpecialization.module.scss';

// TODO: unified components SpecSpecialization and SpecDescription
const SpecSpecialization = ({
  specialization,
  className = '',
  dark = false,
}: ISpecSpecializtionProps) => {
  const spec = specialization.filter(el => !!el).join(', ');

  return (
    <div
      className={cn(styles.root, styles.specialization, {
        [className]: className !== '',
        [styles.darkText]: dark,
      })}
    >
      <Typography className={styles.specializationTypography}>
        {!spec.length ? 'Специализация не указана' : spec}
      </Typography>
    </div>
  );
};

export default SpecSpecialization;
