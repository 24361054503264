import { Dispatch } from 'react';
import { TabsProps } from 'antd';

import { SpecInfoAbout, SpecInfoForm } from '3_features';

const useGetTabItems = (setActiveTab: Dispatch<string>): TabsProps['items'] => [
  {
    key: '1',
    label: 'Основная информация',
    children: <SpecInfoForm setActiveTab={setActiveTab} />,
  },
  {
    key: '2',
    label: 'Информация как о специалисте',
    children: <SpecInfoAbout />,
  },
];

export default useGetTabItems;
