import { RefObject, useEffect } from 'react';

import { ReactComponent as MicroOff } from '1_shared/assets/images/conference/microphone-off.svg';
import { ReactComponent as MicroOn } from '1_shared/assets/images/conference/microphone-on.svg';
import { ReactComponent as CameraOff } from '1_shared/assets/images/conference/video-recorder-off.svg';
import volumeMeter from '1_shared/lib/helpers/conference/volumeMeter';

import styles from './styles.module.scss';

const LocalMedia = (props: {
  videoElement: RefObject<HTMLVideoElement>;
  lockedDevices: any;
}) => {
  const { videoElement, lockedDevices } = props;

  useEffect(() => {
    volumeMeter();
  }, []);

  return (
    <>
      <div className={styles.video} id="voximplantlocalvideo">
        <video
          autoPlay
          ref={videoElement}
          playsInline
        />
      </div>

      <div className={styles.indicatorsContainer}>
        <div className={styles.indicators}>
          {!lockedDevices.microphone ? (
            <MicroOn
              id="local-audio-level-block"
              width={24}
              height={24}
              className={styles.micro}
            />
          ) : (
            <MicroOff
              width={24}
              height={24}
              className={styles.micro}
              style={{ stroke: 'black', fill: 'white' }}
            />
          )}
          {lockedDevices.camera && (
            <CameraOff width={24} height={24} className={styles.camera} />
          )}
        </div>
      </div>
    </>
  );
};
export default LocalMedia;
