import { UploadFile } from 'antd';

import { mergeObjects } from '../ui/OtherInformation/OtherInformation';

import { deleteFile } from './deleteFile';

export const removeFileHandler = async (
  event: UploadFile<any>,
  values: any[],
  setValue: any,
  setValuePath: string,
  user?: any,
  setSpecUser?: any,
  messageApi?: any,
) => {
  if (values?.find((el: any) => el.id === event.uid)) {
    const response = await deleteFile(event?.uid);
    const data = values?.filter((el: any) => el.id !== event.uid);
    if (response) {
      setValue(setValuePath, data);
      if (user && setSpecUser && messageApi) {
        setSpecUser(mergeObjects(user, { media: data }));
        messageApi.open({
          type: 'success',
          content: 'Фотография удалена!',
        });
      }
    }
  }
};