import {
  Anketa,
  ClientSessions,
  FirstFormSpec,
  LoginPage,
  LogoutPage,
  MainPage,
  NotFound,
  OrderPage,
  PcClient,
  PcSpec,
  ReactOrderCurs,
  ReactOrderOk,
  ReactOrderPage,
  SpecialistPersonPage,
  SpecialistsPage,
} from '5_pages';

import { RoutePath } from '1_shared/config/routes';

import { ERoles } from '../../../1_shared/config/enums/ERoles';
import CheckSession from '../../../5_pages/CheckSession/CheckSession';
import ClientMainPage from '../../../5_pages/ClientMainPage/ui/ClientMainPage';
import ConferencePage from '../../../5_pages/Conference/ConferencePage';
import ReactOrderNotOk from '../../../5_pages/ReactOrderNotOk/ui/ReactOrderNotOk';
import SpecialistEditProfile from '../../../5_pages/SpecialistEditProfile/SpecialistEditProfile';

import { Route } from './interfaces/Route';

export const routerConfig: Route[] = [
  {
    path: RoutePath.MAIN,
    element: <MainPage />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.CLIENT_MAIN,
    element: <ClientMainPage />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.LOGOUT,
    element: <LogoutPage />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.SPECIALISTS,
    element: <SpecialistsPage />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.SPECIALIST,
    element: <SpecialistsPage />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.ORDER,
    element: <OrderPage />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.NOT_FOUND,
    element: <NotFound />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.SPECIALIST_PERSON,
    element: <SpecialistPersonPage />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.LOGIN,
    element: <LoginPage />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.PASSWORD,
    element: <LoginPage />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.CLIENT_CABINET,
    element: <PcClient />,
    roles: [ERoles.Client],
  },
  {
    path: RoutePath.CLIENT_SESSIONS,
    element: <ClientSessions />,
    roles: [ERoles.Client],
  },
  {
    path: RoutePath.SPECIALIST_CABINET,
    element: <PcSpec />,
    roles: [ERoles.Spec],
  },
  {
    path: RoutePath.REACT_ORDER_PAGE,
    element: <ReactOrderPage />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.REACT_ORDER_CURS,
    element: <ReactOrderCurs />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.REACT_ORDER_OK,
    element: <ReactOrderOk />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.NEED_HELP,
    element: <ReactOrderNotOk />,
    roles: [ERoles.Unauthorized],
  },
  {
    path: RoutePath.FIRST_FORM,
    element: <FirstFormSpec />,
    roles: [ERoles.Spec],
  },
  {
    path: RoutePath.REACT_ANKETA,
    element: <Anketa />,
    roles: [ERoles.Unauthorized],
  },
  // {
  //   path: RoutePath.REACT_BETTER_PSYCHOLOGICAL,
  //   element: <ReactBetterPsy />,
  //   roles: [Roles.Unauthorized],
  // },
  {
    path: `${RoutePath.CONFERENCE}/:id`,
    element: <ConferencePage />,
    roles: [ERoles.Client, ERoles.Spec],
  },
  {
    path: `${RoutePath.EDIT_PROFILE}`,
    element: <SpecialistEditProfile />,
    roles: [
      // ERoles.Client, // Когда появится возможность редактировать данные у пользователей
      ERoles.Spec,
    ],
  },
  {
    path: `${RoutePath.CHECK_SESSION}`,
    element: <CheckSession />,
    roles: [ERoles.Client, ERoles.Spec, ERoles.Unauthorized],
  },
];
