import useSWRMutation from 'swr/mutation';

import { IFeedbackForm } from '../ui/interfaces/IFeedbackForm';

import { addFeedback } from './api/feedback.api';

const useAddFeedback = () => {
  const { trigger } = useSWRMutation('/ss/api/v1/feedback', addFeedback);
  const addFeedbackFunc = async (data: IFeedbackForm, sessionId: string) => {
    await trigger({ ...data, sessionId });
  };

  return { addFeedbackFunc };
};

export default useAddFeedback;
