import MipLogo2Img from '1_shared/assets/images/mip-logo-2@2x.png';
import { ReactComponent as Diploma } from '1_shared/assets/pictures/stack/icons/diploma.svg';
import { ReactComponent as GlassesBook } from '1_shared/assets/pictures/stack/icons/glasses-book.svg';
import { ReactComponent as GlobeOpenBook } from '1_shared/assets/pictures/stack/icons/globe-open-book.svg';
import { ReactComponent as Graduate } from '1_shared/assets/pictures/stack/icons/graduate-hat-shield.svg';

import 'app/styles/global/global.scss';
import './about.scss';

const About = () => (
  <section className="about" id="about">
    <div className="container about__container">
      <div className="about__inner">
        <div className="about__half about__half--flex">
          <div className="about__heading">
            <h2 className="about__title section-title">О сервисе</h2>
            <p className="about__subtitle">
              <b>DoTherapy</b> — это экосистема психологической помощи.
              Соединяем людей с ведущими экспертами и специалистами в области
              психологии.
            </p>
          </div>
          <div className="about__project">
            <h4 className="about__project-title">
              Проект Московского института психоанализа
            </h4>
            <img
              className="about__project-image"
              src={MipLogo2Img}
              alt="Проект Московского института психоанализа"
            />
          </div>
        </div>
        <div className="about__half about__half--list-wrap">
          <ul className="about__list">
            <li className="about__item">
              <GlobeOpenBook width={49} height={49} />
              <p>
                Мы не просто соединяем вас со специалистами — мы контролируем
                весь процесс на высшем уровне
              </p>
            </li>
            <li className="about__item">
              <GlassesBook width={49} height={49} />
              <p>Разрабатываем свои методологии отбора специалистов</p>
            </li>
            <li className="about__item">
              <Graduate width={49} height={49} />
              <p>Контролируем актуальность знаний</p>
            </li>
            <li className="about__item">
              <Diploma width={49} height={49} />
              <p>
                Привлекаем
                <br /> к работе профильных экспертов
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default About;
