import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';

import { EReminderType } from '../../../1_shared/config/enums/EReminderType';
import { phoneConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { CheckboxCircle } from '../../../1_shared/ui';
import useActionsOrder from '../model/useActionsOrder';
import useGetCalendarEvent from '../model/useGetCalendarEvent';

import { ILocationDetails } from './interfaces/ILocationDetails';

import 'app/styles/global/global.scss';
import './order-details.scss';


const OrderDetails = () => {
  const location = useLocation();
  const state = location.state as ILocationDetails;
  const { reminderSwitchAction } = useActionsOrder();
  const getSessionCalendarEvent = useGetCalendarEvent();

  useLayoutEffect(() => {
    if (state?.phone) {
      reminderSwitchAction(state?.slot?.slotId || '', EReminderType.Phone);
      return;
    }

    reminderSwitchAction(state?.slot?.slotId || '', EReminderType.Email);
  }, []);

  return (
    <section className="order-details">
      <div className="container">
        <div className="order-details__inner">
          <h2 className="order-details__title section-title">
            Параметры вашего заказа
          </h2>

          <ul className="order-details__list">
            {state?.name && (
              <li className="order-details__item">
                <span className="order-details__label">
                  Ваши имя (псевдоним)
                </span>
                <p>{state.name}</p>
              </li>
            )}
            {state?.email && (
              <li className="order-details__item">
                <span className="order-details__label">Email</span>
                <p>{state.email}</p>
                {state?.slot?.slotId && (
                  <CheckboxCircle
                    onChange={() =>
                      reminderSwitchAction(
                        state?.slot?.slotId || '',
                        EReminderType.Email,
                      )
                    }
                  >
                    Оповещать
                  </CheckboxCircle>
                )}
              </li>
            )}
            {state?.phone && (
              <li className="order-details__item">
                <span className="order-details__label">Телефон</span>
                <p>{phoneConvert(state?.phone)}</p>
                {state?.slot?.slotId && (
                  <CheckboxCircle
                    checked
                    onChange={() =>
                      reminderSwitchAction(
                        state?.slot?.slotId || '',
                        EReminderType.Phone,
                      )
                    }
                  >
                    Оповещать
                  </CheckboxCircle>
                )}
              </li>
            )}
            {state?.spec?.secondName && state?.spec?.firstName && (
              <li className="order-details__item">
                <span className="order-details__label">Специалист</span>
                <p>{`${state?.spec?.secondName} ${state?.spec?.firstName}`}</p>
              </li>
            )}
            {state?.slot && (
              <li className="order-details__item">
                <span className="order-details__label">Дата и время</span>
                <p>{dayjs(state.slot.start).format('DD MMMM, HH:mm')}</p>
                <button
                  type="button"
                  onClick={() => {
                    if (state?.slot) getSessionCalendarEvent(state?.slot?.slotId);
                  }}
                  className="order-details__control order-details__control--calendar"
                >
                  Добавить в календарь
                </button>
              </li>
            )}
            {state?.cost !== undefined && (
              <li className="order-details__item">
                <span className="order-details__label">Стоимость</span>
                <p>{`${state?.cost} ₽`}</p>
              </li>
            )}
          </ul>

          <div className="order-details__connect">
            <p>Заметили ошибку? Поможем исправить</p>
            <a
              href="https://t.me/DoTherapy_clientservice"
              className="order-details__connect-btn btn btn--light btn--mini"
            >
              Напишите нам в Телеграмм
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderDetails;
