import { PropsWithChildren, ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ISpecialistListFilters } from '1_shared/config/interfaces';

import { EShowSpecsBySlots } from '../../../1_shared/constants/filterEnums';
import { ESortType } from '../../../4_widgets/SpecialistFilters/ui/SpecialistFilters';

const FilterProvider = ({ children }: PropsWithChildren): ReactElement => {
  const methods = useForm<ISpecialistListFilters>({
    defaultValues: {
      sortRequest: {
        order: undefined,
        sortType: ESortType.BY_NEAREST_SESSION,
      },
      specShowType: EShowSpecsBySlots.SHOW_ALL,
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default FilterProvider;
