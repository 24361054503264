import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

import { RoutePath } from '../../../1_shared/config/routes';

const LogoutPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    navigate(RoutePath.MAIN);
  }, []);

  return (
    <section
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
      }}
    >
      <Spin />
    </section>
  );
};

export default LogoutPage;
