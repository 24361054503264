import { useState } from 'react';
import { Button, Image, Upload, UploadFile } from 'antd';

import { Typography } from '1_shared/ui';

import { FileType } from '../../../config/interfaces/FileType';
import { getBase64 } from '../../../lib/helpers/getBase64';

import { ICustomUploadProps } from './interfaces/ICustomUploadProps';

import './CustomUpload.scss';

const CustomUpload = (props: ICustomUploadProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  return (
    <div className="upload-container">
      <Upload {...props} onPreview={handlePreview}>
        {props?.fileList?.length !== props?.maxCount && (
          <Button
            style={{ backgroundColor: 'transparent' }}
            type="text"
            disabled={props?.disabled}
          >
            <Typography component="span">ЗАГРУЗИТЬ</Typography>
          </Button>
        )}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: visible => setPreviewOpen(visible),
            afterOpenChange: visible => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </div>
  );
};

export { CustomUpload };
