import { z } from 'zod';

import { EEditProfileFieldArrayNames } from '../enums/EFieldArrayNames';

const arrayField = z.array(
  z.object({
    id: z.string().optional(),
    title: z
      .string({
        message: 'Укажите название программы',
      })
      .min(1, {
        message: 'Укажите название программы',
      }),
    issuer: z
      .string({
        message: 'Укажите название организации',
      })
      .min(1, {
        message: 'Укажите название организации',
      }),
    issued_at: z
      .string({
        message: 'Укажите год выдачи',
      })
      .min(1, {
        message: 'Укажите год выдачи',
      })
      .or(
        z
          .number({
            message: 'Укажите год выдачи',
          })
          .min(1, {
            message: 'Укажите год выдачи',
          }),
      ),
  }),
);

export const profileEducationSchema = z.object({
  [EEditProfileFieldArrayNames.EDUCATION]: z.array(
    z.object({
      id: z.string().optional(),
      university: z.string({ message: 'Укажите название учебного заведения' }),
      specialization: z.string({
        message: 'Поле "Специальность" не может быть пустым',
      }),
      date_from: z.string({ message: 'Укажите год выпуска' }).or(
        z.number().min(1, {
          message: 'Укажите год выпуска',
        }),
      ),
    }),
  ),
  [EEditProfileFieldArrayNames.DEGREE]: z.array(
    z.object({
      degree: z.string().optional().nullable(),
    }),
  ),
  [EEditProfileFieldArrayNames.PROFESSIONAL_RETRAINING]: arrayField,
  [EEditProfileFieldArrayNames.ADVANCED_TRAINING]: arrayField,
  [EEditProfileFieldArrayNames.CERTIFICATES]: arrayField,
});

export type IProfileEducationSchema = z.infer<typeof profileEducationSchema>;
