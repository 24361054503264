import React from 'react';

import { Content, Footer, Page } from '1_shared/ui';
import { Header, OrderForm } from '4_widgets';

const OrderPage = (): React.ReactElement => (
  <Page>
    <Header />
    <Content>
      <OrderForm />
    </Content>
    <Footer />
  </Page>
);

export default OrderPage;
