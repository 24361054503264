import { useMemo } from 'react';
import useSWR from 'swr';

import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { currentTimeZone } from '../../../1_shared/constants/timeZone';
import { getSessions } from '../api/sessions.service';

import { IInfoSessionsOutput } from './interfaces/IInfoSessionsOutput';

const useInfoSessions = (): IInfoSessionsOutput => {
  const role = localStorage.getItem('role');

  const { data, isLoading, mutate } = useSWR(
    `/ss/v2/sessions/${role === ERoles.Client ? 'client/' : ''}profile?timezone=${currentTimeZone}`,
    getSessions,
    {
      refreshInterval: 10000,
    },
  );

  const activeSessions = useMemo(() => data?.activeSession, [data]);

  const futureSessions = useMemo(() => data?.futureSessions, [data]);

  const pastSessions = useMemo(() => data?.pastSessions, [data]);

  return {
    activeSession: activeSessions,
    futureSessions,
    pastSessions,
    mutate,
    isLoading,
  };
};

export default useInfoSessions;
