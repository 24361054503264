const getUserConstraints = (camera: any, microphone: any) => {
  const constraints: any = {
    audio: true,
    video: {
      frameRate: 25,
    },
  };
  /*
   * TODO: Нужно проверить, действительно ли включаются нужные девайсы, т. к. айдишников у нас нет
   */
  if (typeof constraints.video !== 'boolean') {
    if (camera) {
      constraints.video.deviceId = camera.id;
    } else {
      constraints.video.facingMode = 'user';
    }
  }

  if (microphone) {
    constraints.audio = {};
    constraints.audio.deviceId = microphone.id;
  }

  return constraints;
};
export default getUserConstraints;
