export const statusOptions = [
  {
    value: 'null',
    label: 'Отсутствует',
  },
  {
    value: 'educator',
    label: 'Педагог',
  },
  {
    value: 'supervisor',
    label: 'Супервизор',
  },
  {
    value: 'professor',
    label: 'Профессор',
  },
];
