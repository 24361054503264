import { z } from 'zod';

import { otherInformationFieldArray } from '../enums/EFieldArrayNames';

export type TStatusOptions = { value: string | null; label: string };

export const IOtherInformationSchema = z.object({
  therapy_hours: z.number(),
  supervision_hours: z.number(),
  professional_status: z.custom<TStatusOptions>(), // custom object for schema
  [otherInformationFieldArray as string]: z.array(z.string()),
});

export type IOtherInformation = z.infer<typeof IOtherInformationSchema>;
