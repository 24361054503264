import { Link } from 'react-router-dom';

import { RoutePath } from '../../../config/routes';

import 'app/styles/global/global.scss';
import './psychologists-near.scss';

const PsychologistsNear = () => (
  <section className="psychologists-near" id="psychologistsNear">
    <div className="container psychologists-near__container">
      <div className="psychologists-near__inner">
        <div className="psychologists-near__content">
          <h2 className="psychologists-near__title section-title">
            Ваш психолог рядом
          </h2>
          <p className="psychologists-near__subtitle">
            Индивидуальный подбор специалиста для онлайн-сессий
          </p>
          <Link
            to={RoutePath.SPECIALISTS}
            state={{ anchor: '#psychologistsNear' }}
            className="psychologists-near__link btn btn--white"
          >
            подобрать специалиста
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default PsychologistsNear;
